import React, { useState } from 'react';
import { TextField, Box, Paper, Typography, Button } from '@mui/material';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const navigate = useNavigate();

  const Apiurl = process.env.REACT_APP_BASE_URL

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${Apiurl}/api/forgotPassword`, { username: email });
      console.log(response.data);
      setOtpSent(true);
      toast.success("OTP sent to your email");
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong. Please try again.");
    }
  };

  const handleOtpSubmit = (e) => {
    e.preventDefault();
    // Handle OTP validation here
    navigate("/ResetPassword");
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="10vh"
      bgcolor="#"
    >
      <ToastContainer />
      <Paper elevation={3} sx={{ padding: 4, maxWidth: 400 }}>
        <Typography variant="h5" align="center" gutterBottom>
          Forgot Password?
        </Typography>
        <Typography variant="body2" align="center" gutterBottom style={{ marginBottom: '16px' }}>
          Enter your email to reset your password
        </Typography>
        <form onSubmit={otpSent ? handleOtpSubmit : handleSubmit}>
          <TextField
            fullWidth
            label="Email"
            type="email"
            margin="normal"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={otpSent}
          />

          {otpSent && (
            <TextField
              fullWidth
              label="OTP"
              type="text"
              margin="normal"
              variant="outlined"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
            />
          )}

          <Button
            fullWidth
            variant="contained"
            color="primary"
            type="submit"
            sx={{ mt: 2, mb: 2 }}
          >
            {otpSent ? 'Verify OTP' : 'Send OTP'}
          </Button>
        </form>
      </Paper>
    </Box>
  );
};

export default ForgotPassword;
