import React, { useState, useEffect, useRef } from 'react';
import { OverlayTrigger, Tooltip, Badge, Dropdown, Form } from 'react-bootstrap';
import { FaSearch, FaBell, FaUserCircle, FaBars } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from './auth/AuthContext';
import axios from 'axios';
import './MainNavbar.css';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Card as MuiCard,
  CardContent,
  Typography,
} from '@mui/material';

const MainNavbar = ({ isSidebarOpen, toggleSidebar }) => {
  const Apiurl = process.env.REACT_APP_BASE_URL;
  const { logout } = useAuth();
  const navigate = useNavigate();

  const [modalOpen, setModalOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [unseenCount, setUnseenCount] = useState(0);
  const [showNotificationsDropdown, setShowNotificationsDropdown] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [isSearchExpanded, setIsSearchExpanded] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const notificationDropdownRef = useRef(null);
  const profileDropdownRef = useRef(null);
  const searchInputRef = useRef(null);
  const token = JSON.parse(localStorage.getItem("data")).token;
  const username = JSON.parse(localStorage.getItem("data")).username;

  useEffect(() => {
    const fetchNotifications = async () => {
      const { data } = await axios.get(`${Apiurl}/api/user/notifications/username=${username}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setNotifications(data);
      const unreadNotifications = data.filter(notification => !notification.read);
      setUnseenCount(unreadNotifications.length);
    };

    fetchNotifications(); // Fetch notifications immediately on mount
  }, [Apiurl, token, username]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        notificationDropdownRef.current &&
        !notificationDropdownRef.current.contains(event.target)
      ) {
        setShowNotificationsDropdown(false);
      }
      if (
        profileDropdownRef.current &&
        !profileDropdownRef.current.contains(event.target)
      ) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleNotificationClick = () => {
    setShowNotificationsDropdown(!showNotificationsDropdown);
  };

  const handleNotificationSelect = async (notification) => {
    if (!notification.read) {
      try {
        await axios.post(`${Apiurl}/api/user/notifications/update`, { id: notification.id, read: true }, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setNotifications((prevNotifications) =>
          prevNotifications.map((n) =>
            n.id === notification.id ? { ...n, read: true } : n
          )
        );
        setUnseenCount((prevCount) => prevCount - 1); 
      } catch (error) {
        console.error("Error marking notification as read", error);
      }
    }

    setSelectedNotification(notification);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedNotification(null);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      searchName(event.target.value);
    }
  };

  const searchName = async (text) => {
    const res = await axios.get(`${Apiurl}/api/search?key=${text}`);
    navigate('/users/Search', { state: { vehicles: res.data } });
  };

  const currentUser = {
    image: null,
    email: 'user@example.com',
  };

  const handleLogout = () => {
    localStorage.removeItem("Profile");
    localStorage.removeItem("data");
    logout(() => navigate('/'));
  };

  return (
    <div className={`app-navbar ${isSidebarOpen ? 'with-sidebar' : 'full-width'}`}>
      <div className="app-navbar-items">
        {/* Sidebar Toggle */}
        <div className='app-navbar-item' onClick={toggleSidebar} style={{ cursor: 'pointer' }}>
          <FaBars className='app-navbar-icon' />
        </div>

        {/* Search */}
        <OverlayTrigger placement='bottom' overlay={<Tooltip id='search-tooltip'>Search</Tooltip>}>
          <div className='app-navbar-item' onClick={() => setIsSearchExpanded(!isSearchExpanded)} ref={searchInputRef} style={{ cursor: 'pointer' }}>
            <FaSearch className='app-navbar-icon' />
            &nbsp; &nbsp;
            {isSearchExpanded && (
              <Form.Control
                type="text"
                placeholder="Search..."
                className="search-input"
                autoFocus
                onKeyDown={handleKeyDown}
              />
            )}
          </div>
        </OverlayTrigger>

        {/* Notifications */}
        <OverlayTrigger placement='bottom' overlay={<Tooltip id='notifications-tooltip'>Notifications</Tooltip>}>
          <div className='app-navbar-item' ref={notificationDropdownRef}>
            <div onClick={handleNotificationClick} style={{ cursor: 'pointer' }}>
              <FaBell className='app-navbar-icon' />
              <sup>
                <Badge bg='danger'>{unseenCount}</Badge>
              </sup>
            </div>
            {showNotificationsDropdown && (
              <Dropdown.Menu className="notification-dropdown-menu" show>
                {notifications.length > 0 ? (
                  <>
                    {notifications.slice(-3).map((notification, index) => (
                      <MuiCard
                        key={index}
                        onClick={() => handleNotificationSelect(notification)}
                        sx={{ width: '300px', padding:"10px",border: '1px solid #e0e0e0'}} 
                      >
                        <CardContent sx={{padding:"0px"}}>
                          <Typography variant="body2" color="textSecondary" component="p" >
                            <div className={`status-dot ${notification.read ? 'read' : 'unread'}`} ></div>
                            {notification.message}
                          </Typography>
                        </CardContent>
                      </MuiCard>
                    ))}

                    <Dropdown.Item as={Link} to="/users/Notification" onClick={() => setShowNotificationsDropdown(false)}>
                      View All
                    </Dropdown.Item>
                  </>
                ) : (
                  <MuiCard className="notification-item">
                    <CardContent>
                      <Typography variant="body2" color="textSecondary" component="p">
                        No new notifications
                      </Typography>
                    </CardContent>
                  </MuiCard>
                )}
              </Dropdown.Menu>
            )}
          </div>
        </OverlayTrigger>

        {/* User Profile */}
        <OverlayTrigger placement='bottom' overlay={<Tooltip id='profile-tooltip'>Profile</Tooltip>}>
          <div className="app-navbar-item" ref={profileDropdownRef}>
            <div onClick={() => setShowDropdown(!showDropdown)} style={{ cursor: 'pointer' }}>
              {currentUser.image ? (
                <img src={currentUser.image} alt={currentUser.email} className='user-avatar' />
              ) : (
                <FaUserCircle className='app-navbar-icon ' />
              )}
            </div>
            {showDropdown && (
              <div className="dropdown-menu show">
                <Link className="dropdown-item" to="/users/Profile" onClick={() => setShowDropdown(false)}>Profile Settings</Link>
                <div className="dropdown-item" onClick={handleLogout}>Logout</div>
              </div>
            )}
          </div>
        </OverlayTrigger>
      </div>

      {/* Modal for Notification Details */}
      <Dialog open={modalOpen} onClose={closeModal} maxWidth="sm" fullWidth>
        <DialogTitle>Notification Details</DialogTitle>
        <DialogContent>
          {selectedNotification && (
            <>
              <p><strong>Subject:</strong> {selectedNotification.subject}</p>
              <p><strong>Message:</strong> {selectedNotification.message}</p>
              <p><strong>Timestamp:</strong> {selectedNotification.timestamp}</p>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color="primary">Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MainNavbar;
