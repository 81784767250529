import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import {
  CircularProgress,
  Container,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Modal,
  Box,
  Grid
} from "@mui/material";
import axios from 'axios';
import { MdDeleteForever, MdArrowBack } from 'react-icons/md';

const EditVehicledetails = () => {
  const [showModal1, setShowModal1] = useState(false);
  const [loading, setLoading] = useState(false);
  const [vehicle, setVehicle] = useState({
    deviceid: "",
    vehicleid: "",
    startdate: "",
    thresholdSpeed: "",
    image: "",
    imageName: "",
  });
  const [selectedImage, setSelectedImage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const Apiurl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem('data')).token;
    setLoading(true);
    axios
      .get(`${Apiurl}/api/devicevehicle/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setVehicle(response.data);
        setSelectedImage(response.data.image);
        setLoading(false);
        toast.success("Fetching details successfully");
      })
      .catch((error) => {
        toast.error('Error Fetching vehicle details. Please try again.');
      });
  }, [id]);

  const handleChange = (e, field) => {
    const actualValue = e.target.value;
    setVehicle((prevVehicle) => ({ ...prevVehicle, [field]: actualValue }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (parseInt(vehicle.thresholdSpeed) < 10) {
      setShowModal(true);
      return;
    }

    const token = JSON.parse(localStorage.getItem("data")).token;
    axios.post(`${Apiurl}/api/devicevehicle/update`, vehicle, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(() => {
        toast.success("Vehicle Details Updated");
        navigate(-1);
      })
      .catch(() => {
        toast.error('Failed to Update Vehicle details. Please try again.');
      });
  };

  const handleImageSelect = (image) => {
    const imageName = image.split('/').pop();
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64String = reader.result.split(',')[1];
      setSelectedImage(image);
      setVehicle((prevVehicle) => ({
        ...prevVehicle,
        image: base64String,
        imageName: imageName,
      }));
    };

    fetch(image)
      .then(res => res.blob())
      .then(blob => {
        reader.readAsDataURL(blob);
      });

    setShowModal1(false);
  };

  const openImageModal = () => {
    setShowModal1(true);
  };

  const closeImageModal = () => {
    setShowModal1(false);
  };

  if (loading) {
    return <CircularProgress />;
  }

  const VehicleImage = [
    '/assets/Bus.png',
    '/assets/Car.png',
    '/assets/Truck.png',
  ];

  return (
    <Container>
        <button className="btn btn-link" onClick={() => navigate(-1)}>
        <MdArrowBack size={35} />
      </button>
      <Box sx={{ mt: 4 }}>
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={10} md={8} lg={6}>
            <Card>
              <CardContent>
                <Typography variant="h4" gutterBottom>Edit Vehicle Details</Typography>
                <form onSubmit={handleSubmit}>
                  <TextField
                    fullWidth
                    label="Device ID"
                    variant="outlined"
                    margin="normal"
                    value={vehicle.deviceid}
                    InputProps={{ readOnly: true }}
                  />
                  <TextField
                    fullWidth
                    label="Vehicle ID"
                    variant="outlined"
                    margin="normal"
                    value={vehicle.vehicleid}
                    onChange={(e) => handleChange(e, "vehicleid")}
                  />
                  <TextField
                    fullWidth
                    label="Start Date"
                    variant="outlined"
                    margin="normal"
                    value={vehicle.startdate}
                    onChange={(e) => handleChange(e, "startdate")}
                  />
                  <TextField
                    fullWidth
                    label="Threshold Speed"
                    variant="outlined"
                    margin="normal"
                    value={vehicle.thresholdSpeed}
                    onChange={(e) => handleChange(e, "thresholdSpeed")}
                  />
                  <TextField
                    fullWidth
                    label="Image"
                    variant="outlined"
                    margin="normal"
                    value={vehicle.imageName}
                    onClick={openImageModal}
                    readOnly
                  />
                  <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
                    {selectedImage && (
                      <img
                        src={selectedImage}
                        alt="Selected Vehicle"
                        style={{ maxWidth: '200px', maxHeight: '200px', borderRadius: '8px', margin: '0 auto' }}
                      />
                    )}
                  </Box>
                  <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={loading}
                    >
                      Submit
                    </Button>
                  </Box>
                </form>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>

      <Modal open={showModal1} onClose={closeImageModal}>
        <Box className="modal-content" sx={{ padding: 2, backgroundColor: 'white', margin: 'auto', maxWidth: '500px', borderRadius: '8px' }}>
          <Typography variant="h6">Select an Image</Typography>
          <Box className="image-selection" sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, marginTop: 2 }}>
            {VehicleImage.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Image ${index}`}
                style={{ height: '90px', cursor: 'pointer', border: selectedImage === image ? '2px solid blue' : 'none' }}
                onClick={() => handleImageSelect(image)}
              />
            ))}
          </Box>
          <Typography sx={{ marginTop: 2 }}>Selected Image: {vehicle.imageName || 'None'}</Typography>
        </Box>
      </Modal>

      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 300,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}>
          <Typography variant="h6" component="h2">
            Threshold Speed Alert
          </Typography>
          <Typography sx={{ mt: 2 }}>
            Threshold speed should be more than 10.
          </Typography>
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
            <Button variant="contained" onClick={() => setShowModal(false)}>OK</Button>
          </Box>
        </Box>
      </Modal>
    </Container>
  );
}

export default EditVehicledetails;
