import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate('/login');
  };

  return (
    <nav className="navbar">
      <div>
        <img src='\assets\track logo 1.png' alt='Logo' style={{ width: "20%" }} />
      </div>
      <div style={{ paddingRight: "10px" }}>
        <button className="login-button" onClick={handleLoginClick} style={{ width: "100px" }}>
          Login
        </button>
      </div>

    </nav>
  );
};

export default Navbar;
