import React, { useState, useEffect } from 'react';
import axios from '../api/baseurl';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal } from 'react-bootstrap';
import './AccountSettings.css'; // Import custom styles
import './Loader.css'; // Corrected import statement


function AccountSetting() {
    const [speed, setSpeed] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [showThresholdErrorModal, setShowThresholdErrorModal] = useState(false);
    const [vehicle, setVehicle] = useState({ thresholdspeed: "" });
    const Apiurl = process.env.REACT_APP_BASE_URL

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (parseInt(vehicle.thresholdspeed) > 10) {
            setShowModal(true);
        } else {
            setShowThresholdErrorModal(true);
        }
    };

    const handleAccept = async () => {
        setLoading(true);
        setShowModal(false);
        const userid = JSON.parse(localStorage.getItem("data")).userid;
        const token = JSON.parse(localStorage.getItem("data")).token;
        const payload = {
            id: vehicle.id,
            option: "YES",
            thresholdspeed: vehicle.thresholdspeed,
            userid: userid
        };

        await axios.post(`${Apiurl}/api/AccountSettingThresholdSpeed/createupdate`, payload, { headers: { Authorization: `Bearer ${token}` } })
            .then((res) => {
                toast("Threshold speed set successfully");
            })
            .catch((error) => {
                toast.error("Error setting threshold speed");
            });

        setLoading(false);
    };

    const handleCancel = async () => {
        setLoading(true);
        setShowModal(false);
        const userid = JSON.parse(localStorage.getItem("data")).userid;
        const token = JSON.parse(localStorage.getItem("data")).token;
        const payload = {
            id: vehicle.id,
            option: "NO",
            thresholdspeed: vehicle.thresholdspeed,
            userid: userid
        };

        await axios.post(`${Apiurl}/api/AccountSettingThresholdSpeed/createupdate`, payload, { headers: { Authorization: `Bearer ${token}` } })
            .then((res) => {
                toast("Threshold speed set successfully");
            })
            .catch((error) => {
                toast.error("Error setting threshold speed");
            });

        setLoading(false);
    };

    const handleChange = (event, field) => {
        let actualValue = event.target.value;
        setVehicle({ ...vehicle, [field]: actualValue });
    };

    const handleOk = () => {
        setShowThresholdErrorModal(false);
    };

    useEffect(() => {
        const userid = JSON.parse(localStorage.getItem("data")).userid;
        const token = JSON.parse(localStorage.getItem("data")).token;

        const fetchData = async () => {
            try {
                const response = await axios.get(`${Apiurl}/api/AccountSettingThresholdSpeed/userid=${userid}`, {
                    headers: { Authorization: `Bearer ${token}` }
                });

                if (response.data) {
                    setVehicle(response.data);
                } else {
                    setVehicle({ ...vehicle, thresholdspeed: "0" });
                }

                setLoading(false);
            } catch (error) {
                toast.error("Error setting threshold speed");
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    return (
        <div className="container">
            {loading ? (
                <div className="loading-indicator">
                    <div className="circle-loader"></div>
                </div>
            ) : (
                <div>
                    <main>
                        <div className=" p-md-5">
                            <div className="col-12">
                                <div className="card card-raised">
                                    <div className="card-body">
                                        <h2 className="card-title">Threshold Speed</h2>
                                        <hr className="mt-0 mb-5" />
                                        <div className="row gx-5">
                                            <form onSubmit={(e) => handleSubmit(e)}>
                                                <div className="row">
                                                    <div className="form-floating-sm mb-2">
                                                        <label htmlFor="thresholdspeed">Threshold Speed</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="thresholdspeed"
                                                            placeholder='Enter threshold speed'
                                                            value={vehicle.thresholdspeed}
                                                            onChange={(e) => handleChange(e, "thresholdspeed")}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="text-end">
                                                    <button
                                                        className="btn btn-primary"
                                                        type="button"
                                                        onClick={(e) => handleSubmit(e)}
                                                    >
                                                        Save
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>

                    {/* Modal for confirmation */}
                    <Modal show={showModal} onHide={() => setShowModal(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Alert</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Do you want to change the threshold speed for all vehicles?
                        </Modal.Body>
                        <Modal.Footer className="d-flex justify-content-between">
                            <span style={{ cursor: 'pointer', color: 'blue' }} onClick={handleAccept}>YES</span>
                            <span style={{ cursor: 'pointer', color: 'red' }} onClick={handleCancel}>NO</span>
                        </Modal.Footer>
                    </Modal>

                    {/* Modal for threshold speed less than 10 */}
                    <Modal show={showThresholdErrorModal} onHide={() => setShowThresholdErrorModal(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Error</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Threshold speed should be more than 10.
                        </Modal.Body>
                        <Modal.Footer className="justify-content-center">
                            <button className="btn btn-primary" onClick={handleOk}>OK</button>
                        </Modal.Footer>
                    </Modal>
                </div>
            )}
        </div>
    );
}

export default AccountSetting;
