import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Modal,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Box,
  Typography,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Switch,
} from '@mui/material';
import { CiEdit } from 'react-icons/ci';
import axios from '../api/baseurl';
import './VehicleList.css';
import { MdDeleteForever } from "react-icons/md";
import Pagination from './Pagination';
 
function VehicleList() {
  const token = JSON.parse(localStorage.getItem('data')).token;
  const Apiurl = process.env.REACT_APP_BASE_URL;
  const {id} = useParams();
  console.log(id);
  const [showModal, setShowModal] = useState(false);
  const [vdevice, setVdevice] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [datalink, setDatalink] = useState({
    userid: JSON.parse(localStorage.getItem("data")).userid,
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedCourses = Array.isArray(vdevice)
  ? vdevice.slice().reverse().slice(startIndex, endIndex)
    : [];
  const [selectedImage, setSelectedImage] = useState({
    image: null,
    imageMeta: null,
    imageName: null,
    colorCode: '',
    deviceid: '',
    disabled: false,
    enddate: '',
    maxspeed: '',
    overridethreshold: '',
    registrationcrtificertificate: null,
    startdate: '',
    thresholdSpeed: '',
    userid: '',
    vehicleid: '',
    vehiclemileage: '',
  });
 
  const VehicleImage = [
    '/assets/Bus.png',
    '/assets/Car.png',
    '/assets/Truck.png',
  ];
 
  const handleImageSelect = (image) => {
    const imageName = image.split('/').pop();
    fetch(image)
      .then(response => response.blob())
      .then(blob => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64data = reader.result.split(',')[1];
          setSelectedImage({
            image: base64data,
            imageMeta: reader.result.split(',')[0],
            imageName: imageName,
          });
        };
        reader.readAsDataURL(blob);
      });
  };
 
  useEffect(() => {
    if (id) {
      VehicleData();
    } else {
      getData();
    }
  }, [id]);
 
 
  const getData = async () => {
    const token = JSON.parse(localStorage.getItem('data')).token;
    const uid = JSON.parse(localStorage.getItem('data')).userid;
 
    try {
      const { data } = await axios.get(`${Apiurl}/api/devicevehicle/userid=${uid}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setVdevice(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
 
    }
  };
 
  const VehicleData = async () => {
    const token = JSON.parse(localStorage.getItem('data')).token;
    try {
      const  data  = await axios.get(`${Apiurl}/api/devicevehicle/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setVdevice(Array.isArray(data.data) ? data.data : [data.data]);
      console.log(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
 
    }
  };
 
  const onClickviewtable = async () => {
    try {
      const response = await axios.post(`${Apiurl}/api/adminpublicLink/createUpdate`, datalink, {
        headers: { Authorization: `Bearer ${token}` },
      });
 
      setDatalink(response.data);
      console.log(response.data);
 
      if (response.data.link) {
        const urlWithUserId = `/public/${response.data.link}`;
        window.open(urlWithUserId, '_blank');
        console.error('Error: Link is empty in the response data');
      }
    } catch (error) {
      console.error('Error in Axios request:', error);
    }
  };
 
  const onClick = () => {
    axios.post(`${Apiurl}/api/adminpublicLink/Update`, datalink, {
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(response => response.data)
      .then(result => {
        const link = result.link;
        const url = `/#/public/${link}`;
        window.open(url, '_blank');
        setDatalink(result);
      })
      .catch(error => {
        console.error('Error in Axios request:', error);
      });
  };
 
  const openImageModal = (vehicleId) => {
    setSelectedVehicle(vehicleId);
    setShowModal(true);
  };
 
  const closeImageModal = () => {
    setSelectedVehicle(null);
    setShowModal(false);
  };
 
  const handleStatusToggle = async (vehicleId, currentStatus) => {
    const token = JSON.parse(localStorage.getItem('data')).token;
    const newStatus = !currentStatus; // Toggle the status
 
    try {
      const response = await axios.post(
        `${Apiurl}/api/devicevehicle/devicevehicleUpdate/vehicleid=${vehicleId}/value=${newStatus}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
 
      if (response.status === 200) {
        setVdevice((prevDevices) =>
          prevDevices.map((device) =>
            device.vehicleid === vehicleId ? { ...device, disabled: newStatus } : device
          )
        );
        toast.success('Status updated successfully!');
      }
    } catch (error) {
      console.error('Error updating status:', error);
      toast.error('Failed to update status');
    }
  };
 
 
  const handleUpdateImage = async (e, id) => {
    const { image, imageMeta, imageName } = selectedImage;
    const vehicleToUpdate = vdevice.find(vehicle => vehicle.id === id);
 
    if (!vehicleToUpdate) {
      toast.error('Vehicle not found');
      return;
    }
 
    try {
      await axios.post(
        `${Apiurl}/api/devicevehicle/update`,
        {
          id,
          image,
          imageMeta,
          imageName,
          colorCode: vehicleToUpdate.colorCode,
          deviceid: vehicleToUpdate.deviceid,
          disabled: vehicleToUpdate.disabled,
          enddate: vehicleToUpdate.enddate,
          maxspeed: vehicleToUpdate.maxspeed,
          overridethreshold: vehicleToUpdate.overridethreshold,
          registrationcrtificertificate: vehicleToUpdate.registrationcrtificertificate,
          startdate: vehicleToUpdate.startdate,
          thresholdSpeed: vehicleToUpdate.thresholdSpeed,
          userid: vehicleToUpdate.userid,
          vehicleid: vehicleToUpdate.vehicleid,
          vehiclemileage: vehicleToUpdate.vehiclemileage,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      toast.success('Image updated successfully!');
    } catch (error) {
      toast.error('Failed to update image data');
    }
  };
 
 
  const onClickViews = () => {
    axios.post(`${Apiurl}/api/adminpublicLink/createUpdate`, datalink, {
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(response => response.data)
      .then(result => {
        setDatalink(result);
        setModalOpen(true);
      })
      .catch(error => {
        console.error('Error in Axios request:', error);
      });
  };
 
  const closeModal = () => {
    setModalOpen(false);
  };
 
  useEffect(() => {
    setLoading(true);
    const userId = JSON.parse(localStorage.getItem('data')).userid;
    const fetchData = async () => {
      try {
        const response = await axios.get(`${Apiurl}/api/adminpublicLink`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        console.log('Data from server:', response.data);
        setData(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error in Axios request:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);
 
  const handleDelete = async (e, vehicle) => {
    e.preventDefault();
    const token = JSON.parse(localStorage.getItem('data')).token;
 
    try {
      await axios.delete(`${Apiurl}/api/devicevehicle`, {
        headers: { Authorization: `Bearer ${token}` },
        data: vehicle,
      });
      setVdevice(vdevice.filter((item) => item.vehicleid !== vehicle.vehicleid));
      toast.success('Deleted successfully');
    } catch (error) {
      console.error('Error deleting device user mapping:', error);
      toast.error('Failed to delete');
    }
  };
 
  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }
 
  return (
    <div className="">
      <ToastContainer />
      <Box padding={3}>
        <Typography variant="h5" gutterBottom>
          Vehicles Device
        </Typography>
        <br />
        <Box mb={1}>
          <Button variant="contained" color="secondary" onClick={(e) => onClickviewtable(e, datalink.link)} style={{ padding: '4px 8px', width: '100px' }}>View All</Button>
          &nbsp;&nbsp;&nbsp;
          <Button variant="contained" color="secondary" onClick={onClickViews} style={{ padding: '4px 8px', width: '100px' }}>Public URL</Button>
 
          <Dialog open={modalOpen} onClose={closeModal} maxWidth="sm" fullWidth>
            <DialogTitle>Public URL</DialogTitle>
            <DialogContent>
              {Object.keys(data).length > 0 ? (
                <Typography>
                  Link:  <a href={`/#/public/${datalink.link}`} target="_blank" rel="noopener noreferrer">
                    {datalink.link}
                  </a>

                </Typography>
              ) : (
                <Typography>Error: Data is empty</Typography>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={(e) => onClick(e, datalink.userid)}>
                {datalink.link ? 'Regenerate URL' : 'Generate URL'}
              </Button>
              <Button onClick={closeModal}>Close</Button>
            </DialogActions>
          </Dialog>
 
          <Link to="/users/AddVehicle" style={{ textDecoration: 'none', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <Button variant="contained" color="primary" style={{ width: '190px' }}>Add Vehicles Device</Button>
          </Link>
        </Box>
        <Box mb={2}></Box>
 
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow style={{ backgroundColor: 'gray', color: 'white' }}>
                <TableCell style={{ color: 'white' }}>Vehicle ID</TableCell>
                <TableCell style={{ color: 'white' }}>Device ID</TableCell>
                <TableCell style={{ color: 'white' }}>Start Date</TableCell>
                <TableCell style={{ color: 'white' }}>Actions</TableCell>
                <TableCell style={{ color: 'white' }}>Image</TableCell>
                <TableCell style={{ color: 'white' }}>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedCourses.length > 0 ? (
                paginatedCourses.map((agent) => (
                  <TableRow key={agent.id}>
                    <TableCell>{agent.vehicleid}</TableCell>
                    <TableCell>{agent.deviceid}</TableCell>
                    <TableCell>{agent.startdate}</TableCell>
                    <TableCell>
                    <Link to={`/users/Location/${agent.deviceid}/${agent.vehicleid}/${(agent.colorCode || '').replace('#', '')}`}>View Location</Link>

                      <br />
                      <Link to={`/users/Vehicalereport/${agent.vehicleid}`}>Vehicle Details</Link>
                      <br />
                    </TableCell>
                    <TableCell>
                      {agent.image && (
                        <div>
                          <img
                            className="img-fluid rounded-circle mb-1"
                            src={`data:image/png;base64,${agent.image}`}
                            alt="..."
                            style={{ maxWidth: "100px", maxHeight: "150px" }}
                          />
                        </div>
                      )}
                      <Button variant="contained" onClick={() => openImageModal(agent.id)} style={{ fontSize: "10px", padding: "4px 8px", width: "100px" }}>Choose Image</Button>
                      <Modal open={showModal} onClose={closeImageModal}>
                        <Box className="modal-content" style={{ padding: '16px', backgroundColor: 'white', margin: 'auto', maxWidth: '500px', borderRadius: '8px' }}>
                          <Typography variant="h6">Select an Image</Typography>
                          <Box className="image-selection" style={{ display: 'flex', flexWrap: 'wrap', gap: '8px', marginTop: '16px' }}>
                            {VehicleImage.map((image, index) => (
                              <img
                                key={index}
                                className={`mb-3 ${selectedImage.imageName === image.split('/').pop() ? 'selected' : ''}`}
                                src={image}
                                alt={`Image ${index}`}
                                style={{ height: '90px', cursor: 'pointer', border: selectedImage.imageName === image.split('/').pop() ? '2px solid blue' : 'none' }}
                                onClick={() => handleImageSelect(image)}
                              />
                            ))}
                          </Box>
 
                          <Typography style={{ marginTop: '16px' }}>Selected Image: {selectedImage.imageName || 'None'}</Typography>
                          <Button variant="contained" color="primary" onClick={(e) => handleUpdateImage(e, selectedVehicle)} style={{ marginTop: '16px' }}>Update</Button>
                        </Box>
                      </Modal>
                    </TableCell>
                    <TableCell>
                      <Switch
                        className='fs-5 mx-2'
                        checked={!agent.disabled}  // Note: If `disabled` is true, switch should be unchecked
                        onChange={() => handleStatusToggle(agent.vehicleid, agent.disabled)}
                        color="primary"
                      />
 
                      <Link to={`/users/EditVehicledetails/${agent.id}`}>
                        <CiEdit className='fs-5 mx-2' />
                      </Link>
 
                      <MdDeleteForever
                        style={{ color: 'red', fontSize: '1.5em', cursor: 'pointer' }}
                        onClick={(e) => handleDelete(e, agent)}
                      />
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan="6">No data available</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          data={vdevice.slice().reverse()}
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          onPageChange={setCurrentPage}
        />
      </Box>
    </div>
  );
}
 
export default VehicleList;
 
 