import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import axios from '../api/baseurl';
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  Grid,
  Card,
  CardContent,
  TextField,
  Button,
  CircularProgress,
  Typography,
  IconButton
} from "@mui/material";
import { IoMdArrowRoundBack } from "react-icons/io";

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let navigate = useNavigate();
    let params = useParams();

    return <Component {...props} params={params} navigate={navigate} />;
  }
  return ComponentWithRouterProp;
}

function Service(props) {
  const date = new Date();
  date.setDate(date.getDate() - 1);
  let day = date.getDate();
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;
  const [loading, setLoading] = useState(false);

  const [service, setService] = useState({
    date: "",
    description: "",
    amount: "",
    vehicleid: props.params.vehicleid,
    file: null,
    filesize: "",
  });

  const url = "/api/maintenance/create";
  const navigate = useNavigate();

  function rsubmit(e) {
    e.preventDefault();
    setLoading(true);

    const token = JSON.parse(localStorage.getItem("data")).token;

    axios
      .post(
        url,
        service,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        setLoading(false);
        console.log(response.data);
        toast.success("Service updated successfully");
        navigate(`/users/Vehicalereport/${props.params.vehicleid}`);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }

  function rhandle(e) {
    const newdata = { ...service };
    newdata[e.target.id] = e.target.value;
    setService(newdata);
  }

  function getBase64FromFile(file) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = function () {
        const [metadata, content] = reader.result.split(",");

        resolve({ metadata, content });
      };

      reader.onerror = function (error) {
        reject(error);
      };
    });
  }

  const handlePdfFileChange = async (e) => {
    const convertedPdf = await getBase64FromFile(e.target.files[0]);
    const pdfObject = {
      ...service,
      filesize: convertedPdf.metadata,
      file: convertedPdf.content,
    };

    setService(pdfObject);
  };

  return (
    <Grid container justifyContent="center">
        
      <Grid item xs={12} sm={10} md={8} lg={6}>
     
        <Card raised>
        <IconButton
        onClick={() => navigate(-1)}
        color="primary"
        sx={{ width: "50px", fontSize: "30px" }}
      >
        <IoMdArrowRoundBack />
      </IconButton>
          <CardContent>
            <Typography variant="h4" gutterBottom>
              Service Details
            </Typography>
            <form onSubmit={rsubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Date"
                    id="date"
                    variant="outlined"
                    type="date"
                    placeholder="Date"
                    value={service.date}
                    onChange={rhandle}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Description"
                    id="description"
                    variant="outlined"
                    placeholder="Description"
                    value={service.description}
                    onChange={rhandle}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Amount"
                    id="amount"
                    variant="outlined"
                    placeholder="Amount"
                    value={service.amount}
                    onChange={rhandle}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    component="label"
                    fullWidth
                  >
                    Upload File
                    <input
                      type="file"
                      hidden
                      onChange={handlePdfFileChange}
                    />
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    SAVE
                  </Button>
                </Grid>
              </Grid>
            </form>
            {loading && <CircularProgress style={{ marginTop: 20 }} />}
          </CardContent>
        </Card>
      </Grid>
      <ToastContainer />
    </Grid>
  );
}

export default withRouter(Service);
