import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../api/baseurl";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { RiDownload2Line } from "react-icons/ri";
import './Payment.css';  
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

function Payment() {
    const [subscript, setSubscript] = useState([]);
    const [bill, setBill] = useState([]);
    const [loading, setLoading] = useState(false);
    const [invoice, setInvoice] = useState("");
    const [pay, setPay] = useState([]);
    const [payment, setPayment] = useState([]);
    const [applicationcode, setApplicationcode] = useState({});

    const date = new Date();
    const year = date.getFullYear();
    const nextyear = year + 1;

    const token = JSON.parse(localStorage.getItem("data")).token;

    const Apiurl = process.env.REACT_APP_BASE_URL

    const getData = async () => {
        setLoading(true);

        const username = JSON.parse(localStorage.getItem("data")).username;
        const paymentStatus = "SUCCESS";
        if (JSON.parse(localStorage.getItem("data")).role === "ROLE_SUPER") {
            await axios.get(`${Apiurl}/api/user/userpayment`, {
                headers: { Authorization: `Bearer ${token}` },
            })
                .then((res) => {
                    setSubscript(res.data);
                    console.log(res.data);
                    setLoading(false);
                })
                .catch(() => {
                    setLoading(false);
                });
        } else if (JSON.parse(localStorage.getItem("data")).role === "ROLE_ADMIN") {
            await axios.get(`/api/user/userpayment/username=${username}/paymentStatus=${paymentStatus}`, {
                headers: { Authorization: `Bearer ${token}` },
            })
                .then((res) => {
                    setBill(res.data);
                    setLoading(false);
                })
                .catch(() => {
                    setLoading(false);
                });
        }
    }

    useEffect(() => {
        getData();
    }, []);

    const download = async (e, id, uname) => {
        setLoading(true);
        await axios.get(`${Apiurl}/api/user/userpayment/id=${id}`, {
            headers: { Authorization: `Bearer ${token}` }
        }).then((response) => {
            setPay(response.data);
            setLoading(false);
        });

        let newpay = 0;
        let newpay1 = 0;
        await axios.get(`${Apiurl}/api/paymentgatewaytax`).then((response) => {
            setPayment(response.data);
            newpay = response.data[0].gsttax;
            newpay1 = response.data[0].gatewaytax;
        });

        await axios.get(`${Apiurl}/api/version/applicationcode`, {
            headers: { Authorization: `Bearer ${token}` }
        }).then((response) => {
            setApplicationcode(response.data);
            const appcode = response.data.applicationcode;
            setInvoice(`IN - ${year} - ${nextyear} - ${appcode}-${id}`);
        });

        const totalcost = +`${pay.amount}`;
        const gsttax = +`${newpay}`;
        const gatewaytax = +`${newpay1}`;
        const Taxamount1 = (gsttax / 100) * totalcost + totalcost;
        const Taxamount2 = (gatewaytax / 100) * Taxamount1;
        const Tax1 = (gsttax / 100) * Taxamount2;
        const TotalTax = Taxamount2 + Tax1;
        const cgst = (gsttax / 2 / 100) * totalcost;
        const sgst = (gsttax / 2 / 100) * totalcost;
        const totalamount = Taxamount1 + TotalTax;
        const totaltransactioncost = Tax1 + Taxamount2;

        const doc = new jsPDF();
        const pageWidth = doc.internal.pageSize.getWidth();

        doc.setFontSize(18);
        doc.setFont('helvetica', 'bold');
        doc.setDrawColor(0, 0, 128);
        doc.setLineWidth(8);
        doc.line(0, 20, pageWidth, 20);

        doc.setFontSize(14);
        doc.text("Invoice", 10, 35);
        doc.setFontSize(10);
        doc.text("Cynefian", 10, 45);
        doc.text("Gundlupet, Karnataka", 10, 55);
        doc.text("1010/27 Mysore Road", 10, 65);
        doc.text("PIN 571111 GSTIN: 29AAJCC7045D1ZM", 10, 75);

        doc.setFontSize(14);
        doc.text("Bill to:", 10, 95);
        doc.setFontSize(10);
        doc.text(`${uname}`, 30, 97);
        doc.setFontSize(14);
        doc.text("Invoice:", 120, 96);
        doc.setFontSize(10);
        doc.text(invoice, 140, 96);
        doc.line(10, 100, 200, 100);

        doc.setFontSize(14);
        doc.text("Total Amount", 20, 110);
        doc.text(pay.amount, 170, 110);

        doc.setLineWidth(0.5);
        doc.line(10, 100, 200, 100);

        doc.setFontSize(10);
        doc.text(`CGST: ${cgst.toFixed(2)}`, 155, 120);
        doc.text(`SGST: ${sgst.toFixed(2)}`, 154, 130);
        doc.text(`Total Amount: ${totalamount.toFixed(2)}`, 150, 140);
        doc.text(`Total Transaction Cost: ${totaltransactioncost.toFixed(2)}`, 140, 150);
        doc.text(`Total Cost: ${totalcost}`, 150, 160);
        doc.setLineWidth(1);
        doc.save("payment_bill.pdf");
    };

    const navigate = useNavigate();

    return (
        <div>
            {loading && <div className="loading"></div>}
            <div className="col-12 mx-xl-auto payment-card">
                <header className="bg-dark">
                    <div className="container-xl px-5">
                        <h1 className="text-white py-1 mb-0 display-8">
                            Account Settings
                        </h1>
                    </div>
                </header>
                <br />
                <div className="container-xl p-6">
                    <div className="mwc-tab-bar">
                        <div
                            className="mwc-tab"
                            onClick={() => navigate("/users/Profile")}
                        >
                            Profile
                        </div>
                        <div
                            className="mwc-tab"
                            onClick={() => navigate("/users/Notification")}
                        >
                            Notifications
                        </div>
                        <div
                            className="mwc-tab"
                            onClick={() => navigate("/users/AccountSubscription")}
                        >
                            Account subscription
                        </div>
                        <div
                            className="mwc-tab active"
                            onClick={() => navigate("/users/Payment")}
                        >
                            Payment
                        </div>
                        <div
                            className="mwc-tab"
                            onClick={() => navigate("/users/Security")}
                        >
                            Security
                        </div>
                    </div>
                </div>
                <Card variant="outlined" style={{ maxWidth: '100%', margin: 'auto' }}>
            <CardContent>
                <Typography variant="h6" component="div" gutterBottom>
                    Payment Details
                </Typography>
                <div className="table-responsive">
                    <table className="table table-bordered">
                        <thead>
                            <tr style={{ fontSize: "17px", backgroundColor: "#002366", color: "white" }}>
                                <th>Id</th>
                                <th>Amount</th>
                                <th>User Name</th>
                                <th>Payment Currency</th>
                                <th>Payment Status</th>
                                <th>Payment Date</th>
                                <th>Razorpay Payment Id</th>
                                <th>Download</th>
                            </tr>
                        </thead>
                        <tbody>
                           
                               {JSON.parse(localStorage.getItem("data")).role === "ROLE_SUPER" && 
                                subscript.map((accd) => (
                                    <tr key={accd.id}>
                                        <td>{accd.id}</td>
                                        <td>{accd.amount}</td>
                                        <td>{accd.username}</td>
                                        <td>{accd.paymentCurrency}</td>
                                        <td>{accd.paymentStatus}</td>
                                        <td>{accd.paymentDate}</td>
                                        <td>{accd.razorpay_payment_id}</td>
                                        <td>
                                            <Button
                                                onClick={(e) => download(e, accd.id, accd.username)}
                                                variant="contained"
                                                color="primary"
                                                startIcon={<RiDownload2Line />}
                                            >
                                                Download
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            {JSON.parse(localStorage.getItem("data")).role === "ROLE_ADMIN" && 
                                <tr key={bill.id}>
                                    <td>{bill.id}</td>
                                    <td>{bill.amount}</td>
                                    <td>{bill.username}</td>
                                    <td>{bill.paymentCurrency}</td>
                                    <td>{bill.paymentStatus}</td>
                                    <td>{bill.paymentDate}</td>
                                    <td>{bill.razorpay_payment_id}</td>
                                    <td className="text-center">
                                        <Button
                                            onClick={(e) => download(e, bill.id, bill.username)}
                                            variant="contained"
                                            color="primary"
                                            startIcon={<RiDownload2Line />}
                                        >
                                            Download
                                        </Button>
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </CardContent>
        </Card>
            </div>
        </div>
    );
}

export default Payment;
