
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

function Editdevice() {
  const token = JSON.parse(localStorage.getItem('data')).token;
  const Apiurl = process.env.REACT_APP_BASE_URL
  const { id } = useParams(); // Get the device ID from React Router
  const updateUrl = `${Apiurl}/api/device/update`;

  const [loading, setLoading] = useState(false);
  const [adevice, setAdevice] = useState({
    device: "",
    serialno: "",
    mobileno: "",
    disabled: false
  });
  
  

  const navigate = useNavigate();

  const handleChange = (event, field) => {
    let actualValue = event.target.value;
    setAdevice({ ...adevice, [field]: actualValue });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${Apiurl}/api/device/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setAdevice(response.data);
      } catch (error) {
        console.error('Error fetching device data:', error.message);
      }
    };
  
    fetchData();
  }, [id, token]);
  

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);


    if (adevice.device) {
      // Update existing product
      axios
        .put(updateUrl, { ...adevice}, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then((response) => response.data)
        .then((result) => {
          console.log(result);
          toast.success("device Successfully Updated");
          navigate("/users/device");
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          toast.error("Error updating device");
          setLoading(false);
        });
    } 
  };



  return (
    <div>
   
      <div id="layoutDrawer_content">
        {loading && <div className="loading"></div>}
        <main>
          <div className="col-9 mx-xl-auto ps-xl-9">
            <div className="card card-raised ">
              <div className="card-body ">
                <div className="card-title"> Edit Device</div>
                <br></br>
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="device">Device</label>
                      <input
                        className="form-control"
                        id="device"
                        value={adevice.device}
                        onChange={(e) => handleChange(e, 'device')}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="device">Serialno</label>
                      <input
                        className="form-control"
                        id="serialno"
                        value={adevice.serialno}
                        onChange={(e) => handleChange(e, 'serialno')}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="device">Mobileno</label>
                      <input
                        className="form-control"
                        id="mobileno"
                        value={adevice.mobileno}
                        onChange={(e) => handleChange(e, 'mobileno')}
                      />
                    </div>
                  </div>

                 

                  <div className="text-end">
                    <input className="btn btn-secondary" type="submit" value="Submit" />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Editdevice;
