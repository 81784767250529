import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Pagination from './Pagination';
import { toast } from "react-toastify";
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tabs,
  Tab,
  CircularProgress,
  Grid,
} from "@mui/material";

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <Component {...props} params={params} navigate={navigate} />;
  }
  return ComponentWithRouterProp;
}

function Vehiclereport(props) {
  const { vehicleid } = useParams();
  const Apiurl = process.env.REACT_APP_BASE_URL;
  const [fuel, setFuel] = useState([]);
  const [fc, setFc] = useState([]);
  const [permit, setPermit] = useState([]);
  const [ecertificate, setEcertificate] = useState([]);
  const [insurance, setInsurance] = useState([]);
  const [service, setService] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [currentPages, setCurrentPages] = useState([1, 1, 1, 1, 1, 1]);
  const itemsPerPage = 6;

  const getData = async () => {
    const date = new Date();
    date.setDate(date.getDate() - 1);
    const token = JSON.parse(localStorage.getItem("data")).token;

    try {
      const fuelResponse = await axios.get(
        `${Apiurl}/api/fuel/vehicleid=${props.params.vehicleid}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setFuel(Array.isArray(fuelResponse.data) ? fuelResponse.data : []);

      const insuranceResponse = await axios.get(
        `${Apiurl}/api/Insurance/vehicleid=${props.params.vehicleid}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setInsurance(
        Array.isArray(insuranceResponse.data) ? insuranceResponse.data : []
      );

      const serviceResponse = await axios.get(
        `${Apiurl}/api/maintenance/vehicleid=${props.params.vehicleid}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setService(
        Array.isArray(serviceResponse.data) ? serviceResponse.data : []
      );

      const fcResponse = await axios.get(
        `${Apiurl}/api/Fitnesscertificate/vehicleid=${props.params.vehicleid}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setFc(Array.isArray(fcResponse.data) ? fcResponse.data : []);

      const permitResponse = await axios.get(
        `${Apiurl}/api/Permit/vehicleid=${props.params.vehicleid}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setPermit(Array.isArray(permitResponse.data) ? permitResponse.data : []);

      const ecertificateResponse = await axios.get(
        `${Apiurl}/api/Emisioncertificate/vehicleid=${props.params.vehicleid}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setEcertificate(
        Array.isArray(ecertificateResponse.data)
          ? ecertificateResponse.data
          : []
      );
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Error fetching data");
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const navigate = useNavigate();

  const addEntity = async (entity) => {
    setLoading(true);
    navigate(`/users/${entity}/${props.params.vehicleid}`);
    setLoading(false);
  };

  const onClickDownload = async (url, id, filename) => {
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    try {
      const response = await axios({
        url: `${Apiurl}/api/${url}/download/id=${id}`,
        method: "GET",
        responseType: "blob",
        headers: { Authorization: `Bearer ${token}` },
      });

      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Error downloading file:", error);
      toast.error("Error downloading file");
    }
    setLoading(false);
  };

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const renderTable = (data, columns, entity) => (
    <TableContainer component={Paper} sx={{ mt: 2 }}>
      <Table>
        <TableHead>
          <TableRow sx={{ backgroundColor: "GrayText", color: "white" }}>
            {columns.map((col) => (
              <TableCell key={col} sx={{ color: "white" }}>
                {col}
              </TableCell>
            ))}
            <TableCell sx={{ color: "white" }}>Download</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item) => (
            <TableRow key={item.id}>
              {columns.map((col) => (
                <TableCell key={col}>{item[col.toLowerCase()]}</TableCell>
              ))}
              <TableCell>
                <Button
                  variant="contained"
                  style={{ width: "100px" }}
                  onClick={() =>
                    onClickDownload(entity, item.id, `${entity}.pdf`)
                  }
                >
                  Download
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const getCurrentPageData = (data, currentPage) => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return Array.isArray(data) ? data.slice().reverse().slice(startIndex, endIndex) : [];
  };

  return (
    <Box p={2}>
      <Card raised>
        <CardContent>
          <Box bgcolor="black" p={2}>
            <Typography variant="h4" component="div" sx={{ color: "white" }}>
              Vehicle Number: {props.params.vehicleid}
            </Typography>
          </Box>
        </CardContent>
      </Card>

      <Box my={3}>
        <Tabs
          value={tabIndex}
          onChange={handleChange}
          centered
          sx={{
            "& .MuiTab-root": {
              marginRight: -5, 
            },
          }}
        >
          <Tab label="Fuel Management" />
          <Tab label="Insurance Details" />
          <Tab label="Service" />
          <Tab label="Fitness Certificate" />
          <Tab label="Permit" />
          <Tab label="Emission Certificate" />
        </Tabs>
      </Box>

      {loading && (
        <Box display="flex" justifyContent="center" my={1}>
          <CircularProgress />
        </Box>
      )}

      {!loading && tabIndex === 0 && (
        <Box my={2}>
          <Card raised>
            <CardContent>
              <Box display="flex" justifyContent="space-between">
                <Typography variant="h6">Fuel Management</Typography>
                <Button
                  variant="contained"
                  onClick={() => navigate(`/users/Fuelmanagment/${vehicleid}`)}
                  style={{ width: "100px" }}
                >
                  Add
                </Button>
              </Box>
              {renderTable(getCurrentPageData(fuel, currentPages[0]), ["Quantity", "Rate", "Amount"], "fuel")}
              <Pagination
                data={fuel}
                currentPage={currentPages[0]}
                itemsPerPage={itemsPerPage}
                onPageChange={(page) => setCurrentPages([page, ...currentPages.slice(1)])}
              />
            </CardContent>
          </Card>
        </Box>
      )}

      {!loading && tabIndex === 1 && (
        <Box my={2}>
          <Card raised>
            <CardContent>
              <Box display="flex" justifyContent="space-between">
                <Typography variant="h6">Insurance Details</Typography>
                <Button
                  variant="contained"
                  onClick={() => navigate(`/users/insurance/${vehicleid}`)}
                  style={{ width: "100px" }}
                >
                  Add
                </Button>
              </Box>
              {renderTable(getCurrentPageData(insurance, currentPages[1]), [
                "policynumber",
                "Year",
                "phonenumber",
                "agent",
                "startdate",
                "enddate",
                "Effective",
              ], "Insurance")}
              <Pagination
                data={insurance}
                currentPage={currentPages[1]}
                itemsPerPage={itemsPerPage}
                onPageChange={(page) => setCurrentPages([currentPages[0], page, ...currentPages.slice(2)])}
              />
            </CardContent>
          </Card>
        </Box>
      )}

      {!loading && tabIndex === 2 && (
        <Box my={2}>
          <Card raised>
            <CardContent>
              <Box display="flex" justifyContent="space-between">
                <Typography variant="h6">Service</Typography>
                <Button
                  variant="contained"
                  onClick={() => navigate(`/users/Service/${vehicleid}`)}
                  style={{ width: "100px" }}
                >
                  Add
                </Button>
              </Box>
              {renderTable(getCurrentPageData(service, currentPages[2]), ["Date", "amount", "Description", ""], "maintenance")}
              <Pagination
                data={service}
                currentPage={currentPages[2]}
                itemsPerPage={itemsPerPage}
                onPageChange={(page) => setCurrentPages([currentPages[0], currentPages[1], page, ...currentPages.slice(3)])}
              />
            </CardContent>
          </Card>
        </Box>
      )}

      {!loading && tabIndex === 3 && (
        <Box my={2}>
          <Card raised>
            <CardContent>
              <Box display="flex" justifyContent="space-between">
                <Typography variant="h6">Fitness Certificate</Typography>
                <Button
                  variant="contained"
                  onClick={() =>
                    navigate(`/users/Fitnesscertificate/${vehicleid}`)
                  }
                  style={{ width: "100px" }}
                >
                  Add
                </Button>
              </Box>
              {renderTable(getCurrentPageData(fc, currentPages[3]), ["fcnumber", "issuedate", "expirydate", ""], "Fitnesscertificate")}
              <Pagination
                data={fc}
                currentPage={currentPages[3]}
                itemsPerPage={itemsPerPage}
                onPageChange={(page) => setCurrentPages([currentPages[0], currentPages[1], currentPages[2], page, ...currentPages.slice(4)])}
              />
            </CardContent>
          </Card>
        </Box>
      )}

      {!loading && tabIndex === 4 && (
        <Box my={2}>
          <Card raised>
            <CardContent>
              <Box display="flex" justifyContent="space-between">
                <Typography variant="h6">Permit</Typography>
                <Button
                  variant="contained"
                  onClick={() => addEntity("permit")}
                  style={{ width: "100px" }}
                >
                  Add
                </Button>
              </Box>
              {renderTable(getCurrentPageData(permit, currentPages[4]), ["permitnumber", "issuedate", "expirydate", ""], "Permit")}
              <Pagination
                data={permit}
                currentPage={currentPages[4]}
                itemsPerPage={itemsPerPage}
                onPageChange={(page) => setCurrentPages([currentPages[0], currentPages[1], currentPages[2], currentPages[3], page, currentPages[5]])}
              />
            </CardContent>
          </Card>
        </Box>
      )}

      {!loading && tabIndex === 5 && (
        <Box my={2}>
          <Card raised>
            <CardContent>
              <Box display="flex" justifyContent="space-between">
                <Typography variant="h6">Emission Certificate</Typography>
                <Button
                  variant="contained"
                  onClick={() =>
                    navigate(`/users/EmissionCertificate/${vehicleid}`)
                  }
                  style={{ width: "100px" }}
                >
                  Add
                </Button>
              </Box>
              {renderTable(getCurrentPageData(ecertificate, currentPages[5]), ["certificationnumber", "issuedate", "expirydate"], "Emisioncertificate")}
              <Pagination
                data={ecertificate}
                currentPage={currentPages[5]}
                itemsPerPage={itemsPerPage}
                onPageChange={(page) => setCurrentPages([currentPages[0], currentPages[1], currentPages[2], currentPages[3], currentPages[4], page])}
              />
            </CardContent>
          </Card>
        </Box>
      )}
    </Box>
  );
}

export default withRouter(Vehiclereport);
