import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "../api/baseurl";

function Tax() {
    const token = JSON.parse(localStorage.getItem("data")).token;
    const Apiurl = process.env.REACT_APP_BASE_URL;
    const [loading, setLoading] = useState(false);
    const [loader, setLoader] = useState(false);
    const [gatewaytaxes, setGatewaytaxes] = useState([]);
    const [mapdevice, setMapdevice] = useState({
        id: "",
        gatewaytax: "",
        gsttax: "",
    });
    const [sidebarOpen, setSidebarOpen] = useState(true); // state to manage sidebar visibility
    const navigate = useNavigate();

    const handleChange = (event, field) => {
        let actualValue = event.target.value;
        setMapdevice({ ...mapdevice, [field]: actualValue });
    };

    const getData = async () => {
        setLoader(true); // Set loader to true before making the API call
        try {
            const res = await axios.get(`${Apiurl}/api/paymentgatewaytax`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setGatewaytaxes(res.data);
        } catch (error) {
            console.error(error);
        } finally {
            setLoader(false); // Set loader to false after the API call completes
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true); // Set loading to true before making the API call
        try {
            await axios.post(
                `${Apiurl}/api/paymentgatewaytax/update`,
                { id: 1, gatewaytax: mapdevice.gatewaytax, gsttax: mapdevice.gsttax },
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
            toast.success("Tax added");
            getData(); // Refresh data after successful submission
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false); // Set loading to false after the API call completes
        }
    };

    useEffect(() => {
        getData(); // Fetch initial data on component mount
    }, []);

    return (
        <div>
            <div id="layoutDrawer_content">
                {loading && (
                    <div className="d-flex justify-content-center align-items-center vh-100">
                        <div className="spinner-border text-primary" role="status" style={{ width: '3rem', height: '3rem' }}>
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                )}
                {!loading && (
                    <main>
                        <div className={`row justify-content-center mt-4 ${sidebarOpen ? "col-md-10 mx-auto" : "col-md-12 mx-auto"}`}>
                            <div className="card card-raised">
                                <div className="card-body">
                                    <h3 className="mb-4">Tax Creation</h3>
                                    <form onSubmit={handleSubmit}>
                                        <div className="form-floating-sm mb-3">
                                            <label htmlFor="gatewaytax">Gateway Tax</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="gatewaytax"
                                                value={mapdevice.gatewaytax}
                                                onChange={(e) => handleChange(e, "gatewaytax")}
                                            />
                                        </div>
                                        <div className="row">
                                            <div className="form-floating-sm mb-3">
                                                <label htmlFor="gsttax">Gst Tax</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="gsttax"
                                                    value={mapdevice.gsttax}
                                                    onChange={(e) => handleChange(e, "gsttax")}
                                                />
                                            </div>
                                        </div>
                                        <div className="text-end">
                                            <button className="btn btn-primary btn-sm" type="submit" style={{ width: "120px" }}>
                                                Update
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </main>
                )}
            </div>
            <br />
            {loader && (
                <div className="d-flex justify-content-center align-items-center">
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            )}
            <div className={`row justify-content-center ${sidebarOpen ? "col-md-10 mx-auto" : "col-md-12 mx-auto"}`}>
                <div className="card card-raised">
                    <div className="card-body">
                        <h3 className="mb-4">Tax Details</h3>
                        <table className="table table-bordered" style={{ position: "relative" }}>
                            <thead>
                                <tr style={{ fontSize: "17px", backgroundColor: "#002366", color: "white", textAlign: "center" }}>
                                    <th>Gateway Tax</th>
                                    <th>Gst Tax</th>
                                </tr>
                            </thead>
                            <tbody>
                                {gatewaytaxes.map((gatewaytax) => (
                                    <tr style={{ textAlign: "center" }} key={gatewaytax.id}>
                                        <td>{gatewaytax.gatewaytax}</td>
                                        <td>{gatewaytax.gsttax}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Tax;
