import React, { useState, useEffect } from "react";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import Pagination from './Pagination';
import './Notification.css';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';

function Notification() {
  const [modalOpen, setModalOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [clickedId, setClickedId] = useState(null);
  const itemsPerPage = 25;
  const [selectedNotification, setSelectedNotification] = useState(null);

  const navigate = useNavigate();

  const getData = async () => {
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    const username = JSON.parse(localStorage.getItem("data")).username;
    const Apiurl = process.env.REACT_APP_BASE_URL;

    try {
      const { data } = await axios.get(`${Apiurl}/api/user/notifications/username=${username}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      const sortedData = data.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
      setNotifications(sortedData);
      
    } catch (error) {
      console.error("Error fetching notifications", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handleNotificationClick = async (note) => {
    setClickedId(note.id);

    const token = JSON.parse(localStorage.getItem("data")).token;
    const Apiurl = process.env.REACT_APP_BASE_URL;

    try {
      await axios.post(`${Apiurl}/api/user/notifications/update`, { id: note.id, read: true }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setNotifications((prevNotifications) =>
        prevNotifications.map((notification) =>
          notification.id === note.id ? { ...notification, read: true } : notification
        )
      );
    } catch (error) {
      console.error("Error marking notification as read", error);
    }
  };

  const handleNotificationSelect = (note) => {
    if (!note.read) {
      handleNotificationClick(note);
    }
    setSelectedNotification(note);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedNotification(null);
  };

  const getCurrentPageData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return notifications.slice(startIndex, endIndex);
  };

  return (
    <div>
      <div id="layoutDrawer_content">
        <main>
          <div className="col-12 mx-xl-auto notification-card">
          <div className=" card-raised mb-5 ">
            <header className="bg-dark ">
              <div className="container-xl px-5">
                <h1 className="text-white py-1 mb-0 display-8">Account Settings</h1>
              </div>
            </header>
            <br />
            <div className="container-xl p-6">
              <div className="mwc-tab-bar">
                <div className="mwc-tab" label="Profile" icon="person" onClick={() => navigate("/users/Profile")}>Profile</div>
                <div className="mwc-tab active" label="Notifications" icon="notifications" onClick={() => navigate("/users/Notification")}>Notifications</div>
                <div className="mwc-tab" label="Account subscription" icon="account_balance" onClick={() => navigate("/users/AccountSubscription")}>Account subscription</div>
                <div className="mwc-tab" label="Payment" icon="account_balance" onClick={() => navigate("/users/Payment")}>Payment</div>
                <div className="mwc-tab" label="Security" icon="security" onClick={() => navigate("/users/Security")}>Security</div>
              </div>
              <div className="container-xl p-6">
                {loading ? (
                  <div className="text-center">
                    <div className="spinner-border text-primary" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <div className="row gx-5 align-items-center">
                    
                      <div className="card-title">Notifications</div>
                      <div className="card-subtitle mb-3">Here are your recent notifications.</div>
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col"></th>
                            <th scope="col">Subject</th>
                            <th scope="col">Message</th>
                            <th scope="col">Timestamp</th>
                          </tr>
                        </thead>
                        <tbody>
                          {getCurrentPageData().map((note) => (
                            <tr
                              key={note.id}
                              onClick={() => handleNotificationSelect(note)}
                              className={`notification-row ${clickedId === note.id ? 'clicked' : ''}`}
                              style={{ backgroundColor: note.read ? '#f5f5f5' : '#ffffff' }} 
                            >
                              <td>
                                <span className={`status-dot ${note.read ? 'read' : 'unread'}`}></span>
                              </td>
                              <td>{note.subject}</td>
                              <td>{note.message}</td>
                              <td>{new Date(note.timestamp).toLocaleString()}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <Pagination
                        data={notifications}
                        currentPage={currentPage}
                        itemsPerPage={itemsPerPage}
                        onPageChange={(page) => setCurrentPage(page)}
                      />
                    </div>
                 
                )}
              </div>
            </div>
          </div>
          </div>
        </main>
      </div>
      <Dialog open={modalOpen} onClose={closeModal} maxWidth="sm" fullWidth>
        <DialogTitle>Notification Details</DialogTitle>
        <DialogContent>
          {selectedNotification && (
            <>
              <p><strong>Subject:</strong> {selectedNotification.subject}</p>
              <p><strong>Message:</strong> {selectedNotification.message}</p>
              <p><strong>Timestamp:</strong> {new Date(selectedNotification.timestamp).toLocaleString()}</p>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color="primary">Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Notification;
