import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { toast, ToastContainer } from "react-toastify";
import {
    Container,
    Tabs,
    Tab,
    Box,
    Typography,
    Card,
    CardContent,
    TextField,
    Button,
    Grid,
    Avatar,
    CircularProgress
} from '@mui/material';
import "react-toastify/dist/ReactToastify.css";

function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        let navigate = useNavigate();
        let params = useParams();
        return <Component {...props} params={params} navigate={navigate} />;
    }
    return ComponentWithRouterProp;
}

function AccountBio(props) {
    const Apiurl = process.env.REACT_APP_BASE_URL;
    const { userid } = useParams();
    const [loading, setLoading] = useState(false);
    const [userDetails, setUserDetails] = useState([]);
    const [user, setUsers] = useState([]);
    const [adminsubscriptionbilling, setAdminsubscriptionbilling] = useState({
        id: '',
        subcode: '',
        subdate: '',
        subUsername: '',    
        transactionLink: '',
        username: '',
        expires: '',
    });
    const [agents, setAgents] = useState({
        userId: '',
        firstname: '',
        lastname: '',
        phone: '',
        email: '',
        company: '',
        country: '',
        image: null,
        imageMeta: '',
    });
    const [activeTab, setActiveTab] = useState(0);

    const navigate = useNavigate();
    const token = JSON.parse(localStorage.getItem('data'))?.token; // Adjusted to handle token extraction

    const getData = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${Apiurl}/api/account-bio/email=${props.params.email}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setAgents(response.data);
        } catch (error) {
            console.error('Error fetching account bio:', error);
            toast.error('Failed to fetch account bio');
        } finally {
            setLoading(false);
        }
    };

    const getData1 = async () => {
        setLoading(true);
        try {
            const usersub = await axios.get(`${Apiurl}/api/user/subscription/username=${props.params.username}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            if (Array.isArray(usersub.data) && usersub.data.length > 0) {
                setAdminsubscriptionbilling(usersub.data[0]);
            }
        } catch (error) {
            console.error('Error fetching subscription:', error);
            toast.error('Failed to fetch subscription');
        } finally {
            setLoading(false);
        }
    };

    const handleImageChange = async (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = () => {
            const [metadata, content] = reader.result.split(',');
            setAgents({
                ...agents,
                imageMeta: metadata,
                image: content
            });
        };

        reader.onerror = (error) => {
            console.error('Error reading file:', error);
            toast.error('Failed to read image file');
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const handleChange = (event, field) => {
        setAgents({ ...agents, [field]: event.target.value });
    };

    const handleChangeSubscriptionBilling = (event, field) => {
        setAdminsubscriptionbilling({
            ...adminsubscriptionbilling,
            [field]: event.target.value
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            const response = await axios.post(`${Apiurl}/api/account-bio/update`, agents, {
                headers: { Authorization: `Bearer ${token}` },
            });
            toast.success('Profile Updated');
            localStorage.setItem('profile', JSON.stringify(response.data));
            navigate('/users/profile');
        } catch (error) {
            console.error('Error updating profile:', error);
            toast.error('Failed to update profile');
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit1 = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            const response = await axios.post(`${Apiurl}/api/user/subscription/update`, adminsubscriptionbilling, {
                headers: { Authorization: `Bearer ${token}` },
            });
            toast.success('Subscription Updated');
            setAdminsubscriptionbilling(response.data);
        } catch (error) {
            console.error('Error updating subscription:', error);
            toast.error('Failed to update subscription');
        } finally {
            setLoading(false);
        }
    };

    const id = JSON.parse(localStorage.getItem('data')).userid;

    const onClickHandler1 = async () => {
      
        try {
            const res = await axios.get(`${Apiurl}/api/deviceusermapping/userid=${id}`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setUserDetails(res.data);
        } catch (error) {
            console.error("Error fetching user details:", error);
            setUserDetails([]); 
            toast.error('Failed to fetch user details');
        }
    };

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);

        // Fetch user details when the "User Details" tab is selected
        if (newValue === 2) {
            onClickHandler1(agents.userId); // Pass the userId from agents to fetch user details
        }
    };

    // Moved UserDetailsDialog outside the main component
    const UserDetailsDialog = ({ userdata }) => {
        const groupedData = userdata.reduce((acc, current) => {
            const { accountlink, deviceid } = current;
            if (!acc[accountlink]) {
                acc[accountlink] = [];
            }
            acc[accountlink].push(deviceid);
            return acc;
        }, {});

        return (
            <Box p={3}>
                <Typography variant="h6" gutterBottom>
                    User Details
                </Typography>
                <Grid>
                    {Object.keys(groupedData).length > 0 ? (
                        Object.keys(groupedData).map((accountlink, index) => (
                            <div key={index}>
                                <Typography>Account Email: {accountlink}</Typography>
                                <Typography>
                                    Devices Linked to this Account:
                                    <ul>
                                        {groupedData[accountlink].map((deviceid, idx) => (
                                            <li key={idx}>{deviceid}</li>
                                        ))}
                                    </ul>
                                </Typography>
                            </div>
                        ))
                    ) : (
                        <Typography>No data available</Typography>
                    )}
                </Grid>
            </Box>
        );
    };

    useEffect(() => {
        getData();
        getData1();
    }, []);

    return (
        <Container>
            <ToastContainer />
            {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
                    <CircularProgress />
                </Box>
            ) : (
                <Box sx={{ width: '100%', mt: 3 }}>
                    <Tabs value={activeTab} onChange={handleTabChange} centered>
                        <Tab label="User Profile" />
                        <Tab label="Subscription" />
                        <Tab label="User Details" />
                    </Tabs>
                    {activeTab === 0 && (
                        <Box p={3}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={4}>
                                    <Card>
                                        <CardContent>
                                            <Typography variant="h6" gutterBottom>
                                                Profile Image
                                            </Typography>
                                            <Box display="flex" flexDirection="column" alignItems="center">
                                                {agents.image && (
                                                    <Avatar
                                                        src={`${agents?.imageMeta},${agents?.image}`}
                                                        alt="Profile"
                                                        sx={{ width: 150, height: 150, mb: 2 }}
                                                    />
                                                )}
                                                <input
                                                    accept="image/*"
                                                    style={{ display: 'none' }}
                                                    id="image-upload"
                                                    type="file"
                                                    onChange={handleImageChange}
                                                />
                                                <label htmlFor="image-upload">
                                                    <Button variant="contained" component="span">
                                                        Upload Image
                                                    </Button>
                                                </label>
                                                <Typography variant="caption" color="textSecondary">
                                                    JPG or PNG no larger than 5 MB
                                                </Typography>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <Card>
                                        <CardContent>
                                            <Typography variant="h6" gutterBottom>
                                                Account Details
                                            </Typography>
                                            <form onSubmit={handleSubmit}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} sm={6}>
                                                        <TextField
                                                            label="First Name"
                                                            fullWidth
                                                            value={agents.firstname}
                                                            onChange={(e) => handleChange(e, 'firstname')}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <TextField
                                                            label="Last Name"
                                                            fullWidth
                                                            value={agents.lastname}
                                                            onChange={(e) => handleChange(e, 'lastname')}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <TextField
                                                            label="Phone"
                                                            fullWidth
                                                            value={agents.phone}
                                                            onChange={(e) => handleChange(e, 'phone')}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <TextField
                                                            label="Email"
                                                            fullWidth
                                                            value={agents.email}
                                                            onChange={(e) => handleChange(e, 'email')}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <TextField
                                                            label="Company"
                                                            fullWidth
                                                            value={agents.company}
                                                            onChange={(e) => handleChange(e, 'company')}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <TextField
                                                            label="Country"
                                                            fullWidth
                                                            value={agents.country}
                                                            onChange={(e) => handleChange(e, 'country')}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Button variant="contained" color="primary" type="submit">
                                                            Save
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </form>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Box>
                    )}
                    {activeTab === 1 && (
                        <Box p={3}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h6" gutterBottom>
                                        Subscription Details
                                    </Typography>
                                    <form onSubmit={handleSubmit1}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    label="Subscription Code"
                                                    fullWidth
                                                    value={adminsubscriptionbilling.subcode}
                                                    onChange={(e) => handleChangeSubscriptionBilling(e, 'subcode')}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    label="Subscription Date"
                                                    fullWidth
                                                    value={adminsubscriptionbilling.subdate}
                                                    onChange={(e) => handleChangeSubscriptionBilling(e, 'subdate')}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    label="Username"
                                                    fullWidth
                                                    value={adminsubscriptionbilling.subUsername}
                                                    onChange={(e) => handleChangeSubscriptionBilling(e, 'subUsername')}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    label="Transaction Link"
                                                    fullWidth
                                                    value={adminsubscriptionbilling.transactionLink}
                                                    onChange={(e) => handleChangeSubscriptionBilling(e, 'transactionLink')}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    label="Expires"
                                                    fullWidth
                                                    value={adminsubscriptionbilling.expires}
                                                    onChange={(e) => handleChangeSubscriptionBilling(e, 'expires')}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Button variant="contained" color="primary" type="submit">
                                                    Save
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </CardContent>
                            </Card>
                        </Box>
                    )}
                    {activeTab === 2 && (
                        <Box p={3}>
                            <UserDetailsDialog userdata={userDetails} />
                        </Box>
                    )}
                </Box>
            )}
        </Container>
    );
}

export default withRouter(AccountBio);
