

 
import moment from "moment";
 
 
export function calculateDistance(lat1, lon1, lat2, lon2) {
 
    // Calculates the distance between two points given their latitude and longitude
      const earthRadius = 6371; // Radius of the Earth in kilometers
      const dLat = toRadians(lat2 - lat1);
      const dLon = toRadians(lon2 - lon1);
   
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
   
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
   
      const distance = earthRadius * c; // Distance in kilometers
      return distance;
    }
   
    // Converts degrees to radians
    function toRadians(degrees) {
      return degrees * (Math.PI / 180);
    }
   
    export function calculateSpeed(prevLatitude, prevLongitude, prevDatetime, currentLatitude, currentLongitude, currentDatetime) {
      // Calculate the time difference between datetimes
      const timeDifference = moment(currentDatetime).diff(moment(prevDatetime), 'seconds');
   
      // Calculate the distance using the calculateDistance function
      const distance = calculateDistance(prevLatitude, prevLongitude, currentLatitude, currentLongitude);
   
      // Calculate the speed in km/h and round it to 2 decimal places
      const speed = (distance / timeDifference) * 3600; // Multiply by 3600 to convert to km/h
      const roundedSpeed = parseFloat(speed.toFixed(2)); // Round to 2 decimal places and convert to floating-point number
     
      return roundedSpeed;
  }
 
 
 