import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
    Box,
    Card,
    CardContent,
    Container,
    Typography,
    Switch,
    CircularProgress,
    TextField,
    Grid,
} from '@mui/material';
import { ImSearch } from "react-icons/im";
import Pagination from '../Component/Pagination'; // Assuming you have a Pagination component

function UserAcc() {
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [searchInput, setSearchInput] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 6;
    const navigate = useNavigate();
    const user = JSON.parse(localStorage.getItem("data"));
    const token = user?.token;
    const Apiurl = process.env.REACT_APP_BASE_URL;

    useEffect(() => {
        const getData = async () => {
            setLoading(true);
            try {
                const { data } = await axios.get(`${Apiurl}/api/accounts`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                setUsers(data);
                setFilteredUsers(data);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        };
    
        getData();
    }, [Apiurl, token]);

    useEffect(() => {
        const filtered = users.filter((user) => {
            const username = user.username?.toLowerCase() || '';
            const email = user.email?.toLowerCase() || '';
            return username.includes(searchInput) || email.includes(searchInput);
        });
        setFilteredUsers(filtered);
    }, [searchInput, users]);

    const handleSearchInputChange = (event) => {
        setSearchInput(event.target.value.toLowerCase());
    };

    const onClickHandler = async (e, username) => {
        const checked = e.target.checked;
        setUpdating(true);
        try {
            await axios.patch(`${Apiurl}/api/accounts/${username}`, { disabled: !checked }, {
                headers: { Authorization: `Bearer ${token}` }
            });
        } catch (error) {
            console.error("Error updating account:", error);
        } finally {
            setUpdating(false);
        }
    };

    const onClickHandler1 = (username) => {
        navigate(`/users/AccountBio/${username}`);
    };

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const paginatedUsers = filteredUsers.slice(startIndex, endIndex);

    return (
        <Container maxWidth="lg">
            <Box mt={5} >
                <Typography variant="h5" gutterBottom>User Account</Typography>
                <Box display="flex" alignItems="center" sx={{ gap: 1 }}>
                    <ImSearch style={{ marginRight: '8px', fontSize: "25px", marginBottom: "25px" }} />
                    <TextField
                        label="Search by Username or Email"
                        variant="outlined"
                        value={searchInput}
                        onChange={handleSearchInputChange}
                        sx={{ width: "300px", mb: 3 }}
                    />
                </Box>
                {loading ? (
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <CircularProgress />
                    </Box>
                ) : (
                    <Grid container spacing={3}>
                        {paginatedUsers.map((user) => (
                            <Grid item xs={12} sm={6} md={4} key={user.id}>
                                <Card raised onClick={() => onClickHandler1(user.username)} sx={{ cursor: 'pointer' }} >
                                    <CardContent>
                                        <Typography variant="subtitle1" align="center">{user.username}</Typography>
                                        <Typography variant="body2" align="center">
                                            Status:
                                            <Switch
                                                checked={!user.disabled}
                                                onChange={(e) => onClickHandler(e, user.username)}
                                                value={user.id}
                                            />
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                )}
                <Pagination
                    data={filteredUsers}
                    currentPage={currentPage}
                    itemsPerPage={itemsPerPage}
                    onPageChange={setCurrentPage}
                    style={{ justifyContent: "flex-end", marginTop: '20px' }}
                />
                {updating && <Typography variant="body2" color="textSecondary">Updating...</Typography>}
            </Box>
        </Container>
    );
}

export default UserAcc;
