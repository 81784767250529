import React, { useState } from 'react';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { IoMdArrowRoundBack } from "react-icons/io";
import { Container, TextField, Button, Box, Typography, CircularProgress, List, ListItem, ListItemText, Card, CardContent, IconButton } from '@mui/material';
import 'react-toastify/dist/ReactToastify.css';

const Account = () => {
  const Apiurl = process.env.REACT_APP_BASE_URL
  const [user, setUser] = useState(/* initial user state */);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    appname: '',
    validity: '',
    username: '',
  });

  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);

  const handleChange = (e) => {
    console.log("Handling change:", e.target.value);
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    if (e.target.value.length > 0) {
      handleSearch(e.target.value.charAt(0));
    } else {
      setSearchResults([]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    navigate("/users/ExternalAccount");

    try {
      const storedData = JSON.parse(localStorage.getItem("data"));

      if (!storedData || !storedData.token) {
        console.error('Token not found in localStorage');
        return;
      }

      const token = storedData.token;

      const response = await axios.post(`${Apiurl}/api/externalaccount/create`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      });

      console.log('Response:', response.data);
      toast.success('Generated successfully!');
    } catch (error) {
      console.error('Error submitting data:', error);

      if (error.response) {
        console.error('Error response:', error.response.data);
      } else {
        toast.error('Something went wrong!!!');
      }
    }
  };

  const handleSearch = async (searchTerm) => {
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.get(`${Apiurl}/api/accounts/key=${searchTerm}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setSearchResults(response.data.filter(user => user.username.startsWith(searchTerm))); // Filter results based on the first character
    } catch (error) {
      console.error('Error searching:', error);
    }
  };

  const handleUsernameSelect = (selectedUsername) => {
    setFormData({
      ...formData,
      username: selectedUsername,
    });
    setSearchResults([]);
  };

  return (
    <Container>
      <IconButton onClick={() => navigate("/users/ExternalAccount")} color="primary" style={{ width: "200px", height: "60px" }}>
        <IoMdArrowRoundBack style={{ fontSize: "30px" }} />
      </IconButton>

      <Container maxWidth="sm" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>

        <Card sx={{ width: '100%', padding: 4, border: '1px solid #ccc', borderRadius: 2 }}>

          <CardContent>
            <ToastContainer />
            <Typography variant="h5" gutterBottom>
              Create External Account
            </Typography>
            <form onSubmit={handleSubmit}>
              <TextField
                label="Appname"
                name="appname"
                value={formData.appname}
                onChange={handleChange}
                fullWidth
                margin="normal"
                variant="outlined"
              />
              <TextField
                label="Validity"
                name="validity"
                value={formData.validity}
                onChange={handleChange}
                fullWidth
                margin="normal"
                variant="outlined"
              />
              <TextField
                label="Username"
                name="username"
                value={formData.username}
                onChange={handleChange}
                fullWidth
                margin="normal"
                variant="outlined"
                autoComplete="off"
              />
              {searchResults.length > 0 && (
                <List>
                  {searchResults.map((adevice) => (
                    <ListItem button key={adevice.username} onClick={() => handleUsernameSelect(adevice.username)}>
                      <ListItemText primary={adevice.username} />
                    </ListItem>
                  ))}
                </List>
              )}
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
                <Button type="submit" variant="contained" color="primary" disabled={loading}>
                  {loading ? <CircularProgress size={24} /> : 'Generate'}
                </Button>
              </Box>
            </form>
          </CardContent>
        </Card>
      </Container>
    </Container>
  );
};

export default Account;
