import React, { useState, useEffect } from 'react';
import axios from '../api/baseurl';
import 'bootstrap/dist/css/bootstrap.min.css';
import Pagination from '../Component/Pagination';

const Features = () => {
    const Apiurl = process.env.REACT_APP_BASE_URL
    const [error, setError] = useState(null);
    const [agents, setAgents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const paginatedCourses = Array.isArray(agents)
        ? agents.slice().slice(startIndex, endIndex)
        : [];

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem("data")).token;

        axios.get(`${Apiurl}/api/features`, {
            headers: { Authorization: `Bearer ${token}` },
        })
            .then(response => response.data)
            .then((result) => {
                setAgents(result);
                setLoading(false);
            })
            .catch((error) => {
                setError(error);
                setLoading(false);
            });
    }, []);

    const onClickhandler = (e, id) => {
        const checked = e.target.checked;
        // Add your logic for handling the switch toggle here
    };

    if (error) {
        return (
            <div>Error: {error.message}</div>
        );
    } else if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center vh-100">
                <div className="spinner-border text-primary" role="status" style={{ width: '3rem', height: '3rem' }}>
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    } else {
        return (
            <div>
                <div className="container my-2">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-12">
                            <h2 className="text-start">Features Details</h2>
                            <br />
                            <table className="table table-bordered">
                                <thead>
                                    <tr style={{ fontSize: "16px", backgroundColor: "#002366", color: "white" }}>
                                        <th>Id</th>
                                        <th>Feature</th>
                                        <th>Description</th>
                                        <th>Feature Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {paginatedCourses.map(agent => (
                                        <tr key={agent.id}>
                                            <td>{agent.id}</td>
                                            <td>{agent.feature}</td>
                                            <td>{agent.description}</td>
                                            <td>
                                                <div className="form-check form-switch">
                                                    <input
                                                        className="form-check-input"
                                                        id="flexSwitchCheckDefault"
                                                        type="checkbox"
                                                        defaultChecked={!agent.disabled}
                                                        onClick={(e) => onClickhandler(e, agent.id)}
                                                        value={agent.id}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <Pagination
                    data={agents.slice().reverse()}
                    currentPage={currentPage}
                    itemsPerPage={itemsPerPage}
                    onPageChange={setCurrentPage}
                />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default Features;
