import React, { useEffect, useState } from "react";
import axios from "../api/baseurl";
import { useNavigate } from "react-router-dom";
import './AccountSubscription.css'; 

function AccountSubscription() {
  const [subscriptionbilling, setSubscriptionbilling] = useState([]);
  const [adminsubscriptionbilling, setAdminsubscriptionbilling] = useState([]);
  const [loading, setLoading] = useState(false);
  const Apiurl = process.env.REACT_APP_BASE_URL

  const getData = async () => {
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;

    const trans = await axios.get(`${Apiurl}/api/user/subscription`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    setSubscriptionbilling(trans.data);
    const usersub = await axios.get(
      `${Apiurl}/api/user/subscription/username=${JSON.parse(localStorage.getItem("data")).username}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    setAdminsubscriptionbilling(usersub.data);
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const navigate = useNavigate();

  return (
    <div>
      {loading && <div className="loading"></div>}
      <div>
        <div className="col-12 mx-xl-auto account-subscription-card">
  
              <header className="bg-dark">
                <div className="container-xl px-5">
                  <h1 className="text-white py-1 mb-0 display-8">
                  Account Settings 
                  </h1>
                </div>
              </header>
              <br></br>

              <div className="container-xl p-6">
                <div className="mwc-tab-bar">
                  <div
                    className="mwc-tab"
                    label="Profile"
                    icon="person"
                    onClick={() => navigate("/users/Profile")}
                  >
                    Profile
                  </div>

                  <div
                    className="mwc-tab"
                    label="Notifications"
                    icon="notifications"
                    onClick={() => navigate("/users/Notification")}
                  >
                    Notifications
                  </div>

                  <div
                    className="mwc-tab active"
                    label="Account subscription"
                    icon="account_balance"
                    onClick={() => navigate("/users/AccountSubscription")}
                  >
                    Account subscription
                  </div>

                  <div
                    className="mwc-tab"
                    label="Payment"
                    icon="account_balance"
                    onClick={() => navigate("/users/Payment")}
                  >
                    Payment
                  </div>

                  <div
                    className="mwc-tab"
                    label="Security"
                    icon="security"
                    onClick={() => navigate("/users/Security")}
                  >
                    Security
                  </div>
                </div>
              </div>

              <table className="table table-bordered ">
                <thead>
                  <tr
                    style={{
                      fontSize: "17px",
                      backgroundColor: "#002366",
                      color: "white",
                    }}
                  >
                    <th>User Name</th>
                    <th>Subscription Code</th>
                    <th>Subscription Date</th>
                    <th>Expiry Date</th>
                  </tr>
                </thead>
                <tbody>
                  {JSON.parse(localStorage.getItem("data")).role ===
                    "ROLE_SUPER" &&
                    subscriptionbilling.map((accd) => {
                      return (
                        <tr key={accd.id}>
                          <td>{accd.username}</td>

                          <td>{accd.subcode}</td>
                          <td>{accd.subdate}</td>
                          <td>{accd.expires}</td>
                        </tr>
                      );
                    })}
                  {JSON.parse(localStorage.getItem("data")).role ===
                    "ROLE_ADMIN" &&
                    adminsubscriptionbilling.map((accd) => {
                      return (
                        <tr key={accd.id}>
                          <td>{accd.username}</td>

                          <td>{accd.subcode}</td>
                          <td>{accd.subdate}</td>
                          <td>{accd.expires}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
   
  );
}

export default AccountSubscription;
