import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Button,
  Typography,
  Grid,
  Box,
  Container,
} from "@mui/material";

function Subscription() {
  const [sub, setSub] = useState([]);
  const [subfeatur, setSubfeatur] = useState([]);
  const [subfeaturmodel, setSubfeaturmodel] = useState([]);

  const Apiurl = process.env.REACT_APP_BASE_URL

  const url = `${Apiurl}/api/subscription-model`;
  const navigate = useNavigate();

  const getData = async () => {
    const sf = await axios.get(`${Apiurl}/api/subscription-features`);
    setSubfeatur(sf.data);
    const sfmm = await axios.get(`${Apiurl}/api/SubmodelandfeatureDo`);
    setSubfeaturmodel(sfmm.data);
    const prof1 = await axios.get(url);
    setSub(prof1.data);
  };

  useEffect(() => {
    getData();
  }, []);

  function handletotalamount(e, id) {
    navigate(`/Register/${id}`);
  }

  function handlepay(e) {
    navigate(`/Register/0`);
  }

  return (
    <Box sx={{ backgroundColor: "#f5f5f5", minHeight: "100vh", py: 4 }}>
      <Container>
        <Button
          variant="outlined"
          color="secondary"
          fullWidth
          onClick={(e) => handlepay(e.id)}
          sx={{ mb: 3 }}
        >
          Free Trial
        </Button>
        <Box textAlign="center" mb={5}>
          <Typography variant="h3" component="h1">
            Subscribe for the Required Plans
          </Typography>
          <Typography variant="subtitle1">
            No contracts, no surprise fees
          </Typography>
        </Box>
        <Grid container spacing={3}>
          {subfeaturmodel.map((subscription) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={subscription.id} display="flex">
              <Card raised sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                <CardHeader
                  title={subscription.submodel}
                  titleTypographyProps={{ variant: 'h6' }}
                />
                <CardContent sx={{ flexGrow: 1 }}>
                  <Box display="flex" alignItems="center" mb={2}>
                    <Typography variant="h4">
                      {subscription.cost}
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary" ml={1}>
                      {subscription.region}
                    </Typography>
                  </Box>
                  <Typography variant="body2" paragraph>
                    For most businesses that want to optimize web queries
                  </Typography>
                  <ul>
                    {subscription.features.map((sbf) => (
                      <li key={sbf.features}>
                        <Box display="flex" alignItems="center">
                          <Typography variant="body2" ml={1}>
                            {sbf.features}
                          </Typography>
                        </Box>
                      </li>
                    ))}
                  </ul>
                </CardContent>
                <CardActions>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={(e) => handletotalamount(e, subscription.id)}
                    sx={{ backgroundColor: "#808080" }}
                  >
                    Choose Plan
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

export default Subscription;
