import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axios from '../api/baseurl';
import './Profile.css';

function Profile() {
  const Apiurl = process.env.REACT_APP_BASE_URL
  const url =`${Apiurl}/api/user/profile`;
  const [loading, setLoading] = useState(false);
  
  const [profile, setProfile] = useState({
    userId: "",
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
    company: "",
    country: "",
    image: null,
    imageMeta: ""
  });

  function getBase64FromFile(file) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        const [metadata, content] = reader.result.split(",");
        resolve({ metadata, content });
      };
      reader.onerror = function (error) {
        reject(error);
      };
    });
  }

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    const convertedImage = await getBase64FromFile(file);
    const imageObject = {
      ...profile,
      imageMeta: convertedImage.metadata,
      image: convertedImage.content,
    };
    setProfile(imageObject);
  }

  const getData = async () => {
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    const { data } = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` },
    });
    setProfile(data);
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const navigate = useNavigate();

  const handleChange = (event, field) => {
    let actualValue = event.target.value;
    setProfile({ ...profile, [field]: actualValue });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const token = JSON.parse(localStorage.getItem("data")).token;

    axios.post(`${Apiurl}/api/user/profile/update`, profile, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => response.data)
      .then((result) => {
        toast.success("Profile Updated");
        localStorage.setItem("profile", JSON.stringify(result));
        navigate("/users/profile");
        setLoading(false);
      });
  };
  

  return (
    <div>
      <div id="layoutDrawer_content">
        <div className="col-xl-12">
          {loading && <div className="loading"></div>}
          <main>
            <div className="col-12 mx-xl-auto profile-card">
             
                  <header className="bg-dark">
                  <div className="container-xl px-5">
                  <h1 className="text-white py-1 mb-0 display-8">
                      Account Settings 
                    </h1>
                    </div>
                  </header>
                  <br />

                  <div className="mwc-tab-bar">
                    <div
                      className="mwc-tab active"
                      label="Profile"
                      icon="person"
                      onClick={() => navigate("/users/Profile")}
                    >
                      Profile
                    </div>

                    <div
                      className="mwc-tab"
                      label="Notifications"
                      icon="notifications"
                      onClick={() => navigate("/users/Notification")}
                    >
                      Notifications
                    </div>

                    <div
                      className="mwc-tab"
                      label="Account subscription"
                      icon="account_balance"
                      onClick={() => navigate("/users/AccountSubscription")}
                    >
                      Account subscription
                    </div>

                    <div
                      className="mwc-tab"
                      label="Payment"
                      icon="account_balance"
                      onClick={() => navigate("/users/Payment")}
                    >
                      Payment
                    </div>

                    <div
                      className="mwc-tab"
                      label="Security"
                      icon="security"
                      onClick={() => navigate("/users/Security")}
                    >
                      Security
                    </div>
                  </div>

                  <div className="container-xl p-6">
                    <div className="row gx-5 align-items-center">
                      <div className="col-xl-6">
                        <div className="card card-raised mb-5">
                          <div className="me-4">
                            {profile.image && (
                              <img
                                className="img-fluid rounded-circle mb-1"
                                id="image"
                                src={`data:${profile.imageMeta};base64,${profile.image}`}
                                alt="User Profile"
                              />
                            )}
                          </div>
                          <div>
                            <div className="card-title text-center">Profile Image</div>
                            <div className="card-subtitle mb-3 text-center">
                              This image will be publicly visible to other users.
                            </div>
                            <div className="text-center">
                              <div className="caption">
                                Upload a new profile image
                              </div>
                              <div className="small font-italic text-muted mb-4">
                                JPG or PNG no larger than 5 MB
                              </div>
                              <label className="btn btn-Success btn-sm" htmlFor="inputImage" style={{ width: '150px' }}>
                                <input
                                  id="inputImage"
                                  type="file"
                                  style={{ display: "none" }}
                                  onChange={handleImageChange}
                                />
                                Upload new image
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-xl-6">
                        <form onSubmit={handleSubmit}>
                          <div className="form-floating-sm mb-3">
                            <label htmlFor="firstname">First Name</label>
                            <input
                              className="form-control"
                              id="firstname"
                              type="text"
                              placeholder="Enter your First Name"
                              value={profile.firstname}
                              onChange={(e) => handleChange(e, "firstname")}
                            />
                          </div>

                          <div className="form-floating-sm mb-3">
                            <label htmlFor="lastname">Last Name</label>
                            <input
                              className="form-control"
                              id="lastname"
                              type="text"
                              placeholder="Enter your Last Name"
                              value={profile.lastname}
                              onChange={(e) => handleChange(e, "lastname")}
                            />
                          </div>

                          <div className="form-floating-sm mb-3">
                            <label htmlFor="phone">Phone Number</label>
                            <input
                              className="form-control"
                              id="phone"
                              type="text"
                              placeholder="Enter your Phone Number"
                              value={profile.phone}
                              onChange={(e) => handleChange(e, "phone")}
                            />
                          </div>

                          <div className="form-floating-sm mb-3">
                            <label htmlFor="email">Email Address</label>
                            <input
                              className="form-control"
                              id="email"
                              type="email"
                              placeholder="Enter your Email"
                              value={profile.email}
                              onChange={(e) => handleChange(e, "email")}
                            />
                          </div>

                          <div className="form-floating-sm mb-3">
                            <label htmlFor="company">Company Name</label>
                            <input
                              className="form-control"
                              id="company"
                              type="text"
                              placeholder="Enter your Company Name"
                              value={profile.company}
                              onChange={(e) => handleChange(e, "company")}
                            />
                          </div>

                          <div className="form-floating-sm mb-3">
                            <label htmlFor="country">Country</label>
                            <input
                              className="form-control"
                              id="country"
                              type="text"
                              placeholder="Enter your Country"
                              value={profile.country}
                              onChange={(e) => handleChange(e, "country")}
                            />
                          </div>

                          <div className="text-end">
                            <button className="btn btn-primary btn-sm" type="submit">
                              Save Changes
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>

                </div>
             
          </main>
        </div>
      </div>
    </div>
  );
}

export default Profile;
