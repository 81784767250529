import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from 'axios';
import { IoMdArrowRoundBack } from "react-icons/io";
import { Button } from 'react-bootstrap';
import {
  IconButton,
} from '@mui/material';

function AddDevice() {
  const Apiurl = process.env.REACT_APP_BASE_URL
  const token = JSON.parse(localStorage.getItem("data")).token;
  const [loading, setLoading] = useState(false);
  const [mapdevice, setMapdevice] = useState({
    deviceid: "",
    userid: "",
    accountlink: ""
  });
  const [User, setUser] = useState([]);
  const [devicemap, setDevicemap] = useState([]);
  const [device, setDevice] = useState([]);
  const [selectedDevices, setSelectedDevices] = useState([]);

  const navigate = useNavigate();

  const handleChange = (event, field) => {
    let actualValue = event.target.value;
    setMapdevice({ ...mapdevice, [field]: actualValue });

    if (field === "accountlink") {
      setSelectedDevices([...selectedDevices, actualValue]);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    console.log(mapdevice);
    axios.post(`${Apiurl}/api/deviceusermapping/create`, mapdevice, { headers: { Authorization: `Bearer ${token}` } })
      .then((response) => {
        navigate("/users/UserDevice");
        toast.success("Device user added");
        setLoading(false);
      })
      .catch((error) => {
        navigate("/users/UserDevice");
        toast.error("The Device ID must be unique");
        setLoading(false);
      });
  };


  const getData = async () => {
    const trans = await axios.get(`${Apiurl}/api/device`, { headers: { Authorization: `Bearer ${token}` } });
    setDevice(trans.data);
    setLoading(false);

    const { data } = await axios.get(`${Apiurl}/api/accounts`, { headers: { Authorization: `Bearer ${token}` } });
    setUser(data);

    // const id = JSON.parse(localStorage.getItem("deviceData")).deviceid;
    // const tran = await axios.get(`/api/device/deviceUpdate/id=${id}/value=true`, { headers: { Authorization: `Bearer ${token}` } });
    // setDevicemap(tran.data);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <div id="layoutDrawer_content">
        {loading && <div className="loading"></div>}
        <main>
        <IconButton onClick={() => navigate(-1)} color="primary" style={{width:"80px"}}>
          <div style={{width:"80px",fontSize:"150%"}} >
                <IoMdArrowRoundBack />
                </div>
              </IconButton>
          <div className="">
            <div className="card card-raised col-8 mx-xl-auto ps-xl-9 mt-4">
              <div className="card-body">
                <h3>Add Deviceuser Mapping</h3>
                
                <br></br>
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="form-floating-sm mb-1">
                      <label htmlFor="Device ID">Device ID</label>
                      <select
                        className="form-control"
                        id="deviceid"
                        value={mapdevice.deviceid}
                        onChange={(e) => handleChange(e, "deviceid")}
                      >
                        <option>select devices</option>
                        {device
                          .filter((mapping) => !selectedDevices.includes(mapping.device))
                          .map((mapping) => (
                            <option value={mapping.device} key={mapping.device}>
                              {mapping.device}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>

                  <div className="row">
                  <div className="form-floating-sm mb-1">
                    <label htmlFor="Userid">User Id</label>
                    <select
                      className="form-control"
                      id="userid"
                      value={mapdevice.userid}
                      onChange={(e) => handleChange(e, "userid")}
                    >
                      <option>select Userid</option>
                      {User.map((adevice) => (
                        <option value={adevice.id} key={adevice.id}>
                          {adevice.id}
                        </option>
                      ))}
                    </select>
                  </div>
                  </div>

                  <div className="row">
                    <div className="form-floating-sm mb-1">
                      <label htmlFor="Accounlink">Account Link</label>
                      <select
                        className="form-control"
                        id="accountlink"
                        value={mapdevice.accountlink}
                        onChange={(e) => handleChange(e, "accountlink")}
                      >
                        <option>select accountlink</option>
                        {User.map((adevice) => (
                          <option value={adevice.username} key={adevice.username}>
                            {adevice.username}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="text-end" >
                    <input
                      className="btn btn-primary" style={{width:"120px"}}
                      type="submit"
                      value="Submit"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default AddDevice;