import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from '../api/baseurl';
import './Security.css'; // Import custom CSS for additional styling

function Security() {
  const token = JSON.parse(localStorage.getItem("data")).token;
  const Apiurl = process.env.REACT_APP_BASE_URL
  const [pass, setpass] = useState({
    password: "",
    passwordConfirm: "",
  });
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleChange = (event, field) => {
    let actualValue = event.target.value;
    setpass({ ...pass, [field]: actualValue });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true); // Set loading to true on form submit
    axios
      .post(`${Apiurl}/api/changePassword`, pass, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setLoading(false); // Set loading to false on successful response
        navigate("/Signin");
        toast.success("Password Change Successfully");
      })
      .catch((error) => {
        setLoading(false); // Set loading to false on error response
        toast.error("Failed to change password");
      });
  };

  return (
    <div>
      {loading && <div className="loading"></div>}
      <div>
        <div className="col-12 mx-xl-auto account-subscription-card">
          <header className="bg-dark">
            <div className="container-xl px-5">
              <h1 className="text-white py-1 mb-0 display-8"> Account Settings </h1>
            </div>
          </header>
          <br></br>

          <div className="container-xl p-6">
            <div className="mwc-tab-bar">
              <div
                className="mwc-tab"
                label="Profile"
                icon="person"
                onClick={() => navigate("/users/Profile")}
              >
                Profile
              </div>

              <div
                className="mwc-tab"
                label="Notifications"
                icon="notifications"
                onClick={() => navigate("/users/Notification")}
              >
                Notifications
              </div>

              <div
                className="mwc-tab "
                label="Account subscription"
                icon="account_balance"
                onClick={() => navigate("/users/AccountSubscription")}
              >
                Account subscription
              </div>

              <div
                className="mwc-tab"
                label="Payment"
                icon="account_balance"
                onClick={() => navigate("/users/Payment")}
              >
                Payment
              </div>

              <div
                className="mwc-tab active"
                label="Security"
                icon="security"
                onClick={() => navigate("/users/Security")}
              >
                Security
              </div>
            </div>
          </div>

          <div className="card-body p-5 ">
            <div className="card-title row justify-content-center">Change Password</div>
            <div className="card-subtitle mb-4 row justify-content-center">
              New passwords must contain at least 8 characters.
            </div>
            <form onSubmit={(e) => handleSubmit(e)}>
              <div className="mb-4 row justify-content-center">
                <div className="col-md-4">
                  <input
                    className="form-control"
                    label="New Password"
                    placeholder="Enter new password"
                    type="password"
                    id="password"
                    value={pass.password}
                    onChange={(e) => handleChange(e, "password")}
                  />
                </div>
              </div>
              <div className="mb-2 row justify-content-center">
                <div className="col-md-4">
                  <input
                    className="form-control"
                    label="Confirm New Password"
                    placeholder="Confirm password"
                    type="password"
                    id="passwordConfirm"
                    value={pass.passwordConfirm}
                    onChange={(e) => handleChange(e, "passwordConfirm")}
                  />
                </div>
              </div>
              <br></br>
              <div className="row justify-content-center">
                <div className="col-md-12 d-flex justify-content-center">
                  <button className="btn btn-primary" type="submit">
                    Reset Password
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Security;
