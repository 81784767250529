import React from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import "./Pagination.css";

const Pagination = ({ data, currentPage, itemsPerPage, onPageChange }) => {
  if (!data || data.length === 0) {
    return null; // Return null or some other JSX if the items array is not available or empty
  }


  const totalPages = Math.ceil(data.length / itemsPerPage);


  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages && page !== currentPage) {
      onPageChange(page);
    }
  };


  const getPageNumbersToShow = () => {
    const pageNumbersToShow = [];
    const minPage = Math.max(1, currentPage - 2);
    const maxPage = Math.min(totalPages, currentPage + 2);
 

    for (let page = minPage; page <= maxPage; page++) {
      pageNumbersToShow.push(page);

    }


    return pageNumbersToShow;
  };


  return (
    <div className='pagination'>
      <div className='arrows' onClick={() => handlePageChange(currentPage - 1)}>
        <MdKeyboardArrowLeft size={35} />
      </div>
      <div className='pageNumbers'>
        {getPageNumbersToShow().map((page) => (
          <div
            className={`num ${currentPage === page ? 'numActive' : ''}`}
            key={page}
            onClick={() => handlePageChange(page)}
          >
            {page}
          </div>
        ))}
      </div>
      <div className='arrows' onClick={() => handlePageChange(currentPage + 1)}>
        <MdKeyboardArrowRight size={35} />
      </div>
    </div>
  );
};

 

export default Pagination;