import React, { useEffect, useState } from 'react';
import MainNavbar from '../MainNavbar';
import axios from 'axios';
import './Admindashboard.css';
import { Card, Typography, Box ,CircularProgress} from '@mui/material';
import BarChart from './BarChart';

function Admindashboard() {
  const [vehicleCount, setVehicleCount] = useState(0);
  const [activeVehicleCount, setActiveVehicleCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [stoppedVehicleCount, setStoppedVehicleCount] = useState(0);
  const Apiurl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    getData();
    fetchData1();
    fetchData();
  }, []);

  const getData = async () => {
    const storedData = JSON.parse(localStorage.getItem('data'));
    if (storedData && storedData.token && storedData.userid) {
      const token = storedData.token;
      const uid = storedData.userid;

      try {
        const response = await axios.get(`${Apiurl}/api/devicevehicle/userid=${uid}`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        setVehicleCount(response.data.length);
       setLoading(false);
      
      } catch (error) {
        console.error('Error fetching data', error);
      } finally {
        setLoading(false); 
      }
    } else {
      console.error('User data not found in localStorage');
      setLoading(false);
    }
  };

  const fetchData1 = async () => {
    const storedData = JSON.parse(localStorage.getItem('data'));
    if (storedData && storedData.token && storedData.userid) {
      const token = storedData.token;
      const uid = storedData.userid;

      try {
        const { data } = await axios.get(`${Apiurl}/api/devicevehicle/Stopped=${uid}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setStoppedVehicleCount(data.length);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data', error);
      }
    }
  };

  const fetchData = async () => {
    const storedData = JSON.parse(localStorage.getItem('data'));
    if (storedData && storedData.token && storedData.userid) {
      const token = storedData.token;
      const uid = storedData.userid;

      try {
        const { data } = await axios.get(`${Apiurl}/api/devicevehicle/running=${uid}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setActiveVehicleCount(data.length);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data', error);
      }
    }
  };

  
  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div>
     
      {loading ? (
        <Box className="loading-container">
          <CircularProgress />
        </Box>
      ) : (
        <Box className="dashboard-container">
          <Box className="card-row">
            <Card className="dashboard-card total-vehicles-card" style={{ backgroundColor: "violet" }}>
              <Typography variant="h6" component="h2">
                Total Vehicles: {vehicleCount}
              </Typography>
            </Card>
            <Card className="dashboard-card active-vehicles-card" style={{ backgroundColor: "lightskyblue" }}>
              <Typography variant="h6" component="h2">
                Active Vehicles: {activeVehicleCount}
              </Typography>
            </Card>
            <Card className="dashboard-card stopped-vehicles-card" style={{ backgroundColor: "darkcyan" }}>
              <Typography variant="h6" component="h2">
                Stopped Vehicles: {stoppedVehicleCount}
              </Typography>
            </Card>
          </Box>
          <Card style={{ padding: "25px" }}>
            <BarChart />
          </Card>
        </Box>
      )}
    </div>
  );
}

export default Admindashboard;
