import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import {  MdArrowBack } from "react-icons/md";

import axios from '../api/baseurl';
import './Profile.css';
import { Button, ButtonBase } from "@mui/material";

function PublicUserProfile() {
  const { userid } = useParams();
  const Apiurl = process.env.REACT_APP_BASE_URL;
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState([]);
  
  const [publicUser, setPublicUser] = useState({
    phone: "",
    email: "",
    password: "",
    passwordConfirm: "",
  });

  function getBase64FromFile(file) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        const [metadata, content] = reader.result.split(",");
        resolve({ metadata, content });
      };
      reader.onerror = function (error) {
        reject(error);
      };
    });
  }

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    const convertedImage = await getBase64FromFile(file);
    const imageObject = {
      ...profile,
      imageMeta: convertedImage.metadata,
      image: convertedImage.content,
    };
    setProfile(imageObject);
  }

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    const { data } = await axios.get(`${Apiurl}/api/user/useraddress/${userid}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    setProfile(data);
    setPublicUser((prevState) => ({
      ...prevState,
      email: data.username, // Assuming profile.username is the email
    }));
    setLoading(false);
  };

  const navigate = useNavigate();

  const handleChange = (event, field) => {
    let actualValue = event.target.value;
    setPublicUser({ ...publicUser, [field]: actualValue });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const token = JSON.parse(localStorage.getItem("data")).token;

    axios.post(`${Apiurl}/api/users/create`, publicUser, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => response.data)
      .then((result) => {
        toast.success("Profile Updated");
        localStorage.setItem("profile", JSON.stringify(result));
        navigate(`/users/Publicuser`);
        setLoading(false);
      });
  };

  return (
    <div>
      <div id="layoutDrawer_content">
        <div className="col-xl-12">
          {loading && <div className="loading"></div>}
          <main>
            <div className="col-12 mx-xl-auto profile-card">
            <Button onClick={() => navigate(-1)} startIcon={<MdArrowBack size={25} fontSize={90} />} className="mb-2" >
        
        </Button>
              <header className="bg-dark">
                <div className="container-xl px-5">
                  <h1 className="text-white py-1 mb-0 display-8">
                    Public Users 
                  </h1>
                </div>
              </header>
              <br />

              <div className="mwc-tab-bar">
                <div
                  className="mwc-tab active"
                  label="Profile"
                  icon="person"
                  onClick={() => navigate("/users/PublicUserProfile")}
                >
                  Profile
                </div>

                <div
                  className="mwc-tab"
                  label="UserVehicleMapping"
                  icon="vehicles"
                  onClick={() => navigate(`/users/PublicUsername/${profile.id}`)}
                >
                  UserVehicleMapping
                </div>
              </div>

              <div className="container-xl p-6">
                <div className="row gx-5 align-items-center">
                  <div className="col-xl-6">
                    <div className="card card-raised mb-5">
                      <div className="me-4">
                        {profile.image && (
                          <img
                            className="img-fluid rounded-circle mb-1"
                            id="image"
                            src={`data:${profile.imageMeta};base64,${profile.image}`}
                            alt="User Profile"
                          />
                        )}
                      </div>
                      <div>
                        <div className="card-title text-center">Profile Image</div>
                        <div className="card-subtitle mb-3 text-center">
                          This image will be publicly visible to other users.
                        </div>
                        <div className="text-center">
                          <div className="caption">
                            Upload a new profile image
                          </div>
                          <div className="small font-italic text-muted mb-4">
                            JPG or PNG no larger than 5 MB
                          </div>
                          <label className="btn btn-Success btn-sm" htmlFor="inputImage" style={{ width: '150px' }}>
                            <input
                              id="inputImage"
                              type="file"
                              style={{ display: "none" }}
                              onChange={handleImageChange}
                            />
                            Upload new image
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-6">
                    <form onSubmit={handleSubmit}>
                      <div className="form-floating-sm mb-3">
                        <label htmlFor="passwordConfirm">Password Confirm</label>
                        <input
                          className="form-control"
                          id="passwordConfirm"
                          type="text"
                          placeholder="Enter your Password Confirmation"
                          value={publicUser.passwordConfirm}
                          onChange={(e) => handleChange(e, "passwordConfirm")}
                        />
                      </div>

                      <div className="form-floating-sm mb-3">
                        <label htmlFor="password">Password</label>
                        <input
                          className="form-control"
                          id="password"
                          type="text"
                          placeholder="Enter your Password"
                          value={publicUser.password}
                          onChange={(e) => handleChange(e, "password")}
                        />
                      </div>

                      <div className="form-floating-sm mb-3">
                        <label htmlFor="phone">Phone Number</label>
                        <input
                          className="form-control"
                          id="phone"
                          type="text"
                          placeholder="Enter your Phone Number"
                          value={publicUser.phone}
                          onChange={(e) => handleChange(e, "phone")}
                        />
                      </div>

                      <div className="form-floating-sm mb-3">
                        <label htmlFor="email">Email Address</label>
                        <input
                          className="form-control"
                          id="email"
                          type="email"
                          placeholder="Enter your Email"
                          value={publicUser.email}
                          readOnly
                        />
                      </div>

                      <div className="text-end">
                        <button className="btn btn-primary btn-sm" type="submit">
                          Save Changes
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

            </div>
          </main>
        </div>
      </div>
    </div>
  );
}

export default PublicUserProfile;
