  import React, { useState } from "react";
  import { Link, useNavigate, useParams } from "react-router-dom";
  import { toast, ToastContainer } from "react-toastify";
  import "react-toastify/dist/ReactToastify.css";
  import axios from "axios";
  import { Box, Button, TextField, Typography, Paper, Checkbox, FormControlLabel } from '@mui/material';


  function withRouter(Component) {
    function ComponentWithRouterProp(props) {
      let params = useParams();
      return <Component {...props} params={params} />;
    }
    return ComponentWithRouterProp;
  }

  const Register = (props) => {
    const Apiurl = process.env.REACT_APP_BASE_URL
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [admindata, setAdata] = useState({
      username: "",
      password: "",
      passwordConfirm: "",
    });

    const url = `${Apiurl}/api/register`;
   

    const rsubmit = (e) => {
      e.preventDefault();
      setLoading(true);

      const registrationData = {
        username: admindata.username,
        password: admindata.password,
        passwordConfirm: admindata.passwordConfirm,
      };

      localStorage.setItem("RegistrationData", JSON.stringify(registrationData));

      axios
        .post(url, registrationData)
        .then((res) => {
          localStorage.setItem("RegistrationUsername", res.data.username);
          navigate(`/Otp/${props.params.id}`);
          toast.success("OTP sent to your mail");
          setLoading(false); 
        }
        )
        .catch((error) => {
          console.error(error);
          if (error.response) {
            const { status, data } = error.response;
            if (status === 404) {
              toast.error("The requested endpoint was not found (404)");
            } else if (status === 400) {
              toast.error(data.message || "An error occurred");
            } else {
              toast.error("An unexpected error occurred");
            }
          } else {
            toast.error("Network error or server is not responding");
          }
          setLoading(false);
        });
    };

    const rhandle = (e) => {
      const { id, value } = e.target;
      setAdata((prevData) => ({
        ...prevData,
        [id]: value,
      }));
    };

    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        bgcolor="#f5f5f5"
      >
        <Paper elevation={3} sx={{ padding: 4, maxWidth: 400 }}>
          <Typography variant="h5" align="center" gutterBottom>
            Sign Up
          </Typography>
          <form onSubmit={rsubmit}>
            <TextField
              fullWidth
              label="Email"
              id="username"
              type="email"
              margin="normal"
              variant="outlined"
              placeholder="Email"
              value={admindata.username}
              onChange={rhandle}
              required
            />
            <TextField
              fullWidth
              label="Password"
              id="password"
              type="password"
              margin="normal"
              variant="outlined"
              placeholder="Password"
              value={admindata.password}
              onChange={rhandle}
              required
            />
            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              Use 8 or more characters with a mix of letters, numbers & symbols.
            </Typography>
            <TextField
              fullWidth
              label="Confirm Password"
              id="passwordConfirm"
              type="password"
              margin="normal"
              variant="outlined"
              placeholder="Password confirmation"
              value={admindata.passwordConfirm}
              onChange={rhandle}
              required
            />
            <FormControlLabel
              control={<Checkbox color="primary" />}
              label={
                <Typography variant="body2">
                  I Accept the <Link to="#">Terms</Link>.
                </Typography>
              }
            />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              sx={{ mt: 2, mb: 1 }}
              disabled={loading}
            >
              Submit
            </Button>
            <Button
              fullWidth
              variant="outlined"
              color="primary"
              sx={{ mb: 2 }}
              onClick={() => navigate("/")}
            >
              Cancel
            </Button>
          </form>
        </Paper>
        <ToastContainer />
      </Box>
    );
  };

  export default withRouter(Register);
