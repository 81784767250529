import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import axios from 'axios';
import { useParams, useNavigate } from "react-router-dom";
import {
  Grid,
  Card,
  CardContent,
  TextField,
  Button,
  CircularProgress,
  Typography,
  IconButton
} from "@mui/material";
import { IoMdArrowRoundBack } from "react-icons/io";


function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let navigate = useNavigate();
    let params = useParams();

    return <Component {...props} params={params} navigate={navigate} />;
  }
  return ComponentWithRouterProp;
}

function Insurance(props) {
  const date = new Date();
  date.setDate(date.getDate() - 1);
  let day = date.getDate();
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;

  const [loading, setLoading] = useState(false);
  const user = JSON.parse(localStorage.getItem('data'));
  const Apiurl = process.env.REACT_APP_BASE_URL;

  const { vehicleid } = useParams();
  const [insurance, setInsurance] = useState({
    policynumber: "",
    year: "",
    phonenumber: "",
    agent: "",
    startdate: "",
    enddate: "",
    effective: "",
    vehicleid: vehicleid,
    file: null,
    filesize: "",
  });
  

  function getBase64FromFile(file) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = function () {
        const [metadata, content] = reader.result.split(",");

        resolve({ metadata, content });
      };

      reader.onerror = function (error) {
        reject(error);
      };
    });
  }

  const handleFileChange = (e) => {
    const actualvalue = e.target.files[0];
    if (e.target.files) {
      setInsurance({ ...insurance, file: actualvalue });
    }
  };

  const handleUploadClick = (e) => {
    if (!insurance.file) {
      return;
    }
  };

  const navigate = useNavigate();

  function rsubmit(e, ifile) {
    e.preventDefault();
    setLoading(true);

    const token = JSON.parse(localStorage.getItem("data")).token;

    axios
      .post(
        `${Apiurl}/api/Insurance/create`,
        insurance,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        setLoading(false);
        console.log(response.data);

        toast.success("Insurance updated successfully");
        navigate(`/users/Vehicalereport/${vehicleid}`); // Navigate to correct route with vehicleid
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        toast.error("Failed to update insurance");
      });
  }

  const fileType = ["application/pdf"];

  const handlePdfFileChange = async (e) => {
    const convertedPdf = await getBase64FromFile(e.target.files[0]);
    const pdfObject = {
      ...insurance,
      filesize: convertedPdf.metadata,
      file: convertedPdf.content,
    };

    setInsurance(pdfObject);
  };

  function rhandle(e) {
    const newdata = { ...insurance };
    newdata[e.target.id] = e.target.value;
    setInsurance(newdata);
  }

  return (
    
    <Grid container justifyContent="center">
        
      <Grid item xs={12} sm={10} md={8} lg={6}>
    
        <Card raised>
        <IconButton
        onClick={() => navigate(-1)}
        color="primary"
        sx={{ width: "50px", fontSize: "30px" }}
      >
        <IoMdArrowRoundBack />
      </IconButton>
            
       
          <CardContent>
            <Typography variant="h4" gutterBottom>
              Insurance Details
            </Typography>
            <form onSubmit={(e) => rsubmit(e, insurance.file)}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Policy Number"
                    id="policynumber"
                    variant="outlined"
                    placeholder="Policy Number"
                    value={insurance.policynumber}
                    onChange={(e) => rhandle(e)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Vehicle ID"
                    id="vehicleid"
                    variant="outlined"
                    placeholder="Vehicle ID"
                    value={vehicleid} // Display vehicleid from props.params
                    disabled // Prevent editing
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Year"
                    id="year"
                    variant="outlined"
                    placeholder="Year"
                    value={insurance.year}
                    onChange={(e) => rhandle(e)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Phone Number"
                    id="phonenumber"
                    variant="outlined"
                    placeholder="Phone Number"
                    value={insurance.phonenumber}
                    onChange={(e) => rhandle(e)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Agent"
                    id="agent"
                    variant="outlined"
                    placeholder="Agent"
                    value={insurance.agent}
                    onChange={(e) => rhandle(e)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Start Date"
                    id="startdate"
                    variant="outlined"
                    type="date"
                    placeholder="Start Date"
                    value={insurance.startdate}
                    onChange={(e) => rhandle(e)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="End Date"
                    id="enddate"
                    variant="outlined"
                    type="date"
                    placeholder="End Date"
                    value={insurance.enddate}
                    onChange={(e) => rhandle(e)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Effective"
                    id="effective"
                    variant="outlined"
                    placeholder="Effective"
                    value={insurance.effective}
                    onChange={(e) => rhandle(e)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    component="label"
                    fullWidth
                  >
                    Upload File
                    <input
                      type="file"
                      hidden
                      onChange={(e) => handlePdfFileChange(e)}
                    />
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    style={{ width: "120px" }}
                  >
                    SAVE
                  </Button>
                </Grid>
              </Grid>
            </form>
            {loading && <CircularProgress style={{ marginTop: 20 }} />}
          </CardContent>
        </Card>
      </Grid>
      <ToastContainer />
    </Grid>
  );
}

export default withRouter(Insurance);
