
import axios from "axios";
const Apiurl = process.env.REACT_APP_BASE_URL
 
export const token = () => {
  const token=JSON.parse(localStorage.getItem("data")).token;
  if (token) {
      return token;
  } else {
      return ""
  }
};
export const loginUser = (logindata) => {
  return axios.post(`${Apiurl}/api/authenticate`, logindata)
    .then((response) => response.data);
};
export const usersaccount = (useraccount) => {
  return axios
    .get(`${Apiurl}/api/accounts/username`, useraccount)
    .then((response) => response.data);
};