import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import axios from '../api/baseurl';
import { useParams, useNavigate } from "react-router-dom";
import {
  Grid,
  Card,
  CardContent,
  TextField,
  Button,
  CircularProgress,
  useMediaQuery,
  Typography,
  IconButton
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { IoMdArrowRoundBack } from "react-icons/io";

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let navigate = useNavigate();
    let params = useParams();

    return <Component {...props} params={params} navigate={navigate} />;
  }
  return ComponentWithRouterProp;
}

function Fuelmanagment(props) {
  const [loading, setLoading] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true); // Adjust this based on your sidebar logic

  const date = new Date();
  date.setDate(date.getDate() - 1);
  let day = date.getDate();
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;
  const [fuel, setFuel] = useState({
    rate: "",
    quantity: "",
    date: "",
    vehicleid: props.params.vehicleid,
    amount: "",
    file: null,
    filesize: "",
  });
  const url = "/api/fuel/create";
  const navigate = useNavigate();

  function rsubmit(e) {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;

    axios
      .post(
        url,
        fuel,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        setLoading(false);
        console.log(response.data);
        toast.success("Fuel update successfully");
        navigate(`/users/Vehicalereport/${props.params.vehicleid}`);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        toast.error("Failed to update fuel");
      });
  }

  function rhandle(e) {
    const newdata = { ...fuel };
    newdata[e.target.id] = e.target.value;
    setFuel(newdata);
  }

  function getBase64FromFile(file) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        const [metadata, content] = reader.result.split(",");
        resolve({ metadata, content });
      };
      reader.onerror = function (error) {
        reject(error);
      };
    });
  }

  const handlePdfFileChange = async (e) => {
    const convertedPdf = await getBase64FromFile(e.target.files[0]);
    const pdfObject = {
      ...fuel,
      filesize: convertedPdf.metadata,
      file: convertedPdf.content,
    };
    setFuel(pdfObject);
  };

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("xs"));
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div className={`container-fluid ${isSidebarOpen ? "col-xl-12" : "col-12"} `}>
      <br />
      <br />
    
      <Grid container justifyContent="center">
     
        <Grid item xs={12} sm={10} md={8} lg={6}>
          
          <Card raised>
          <IconButton
        onClick={() => navigate(-1)}
        color="primary"
        sx={{ width: "50px", fontSize: "30px" }}
      >
        <IoMdArrowRoundBack />
      </IconButton>
            <CardContent>
              <Typography variant="h4" gutterBottom>
                Fuel Details
              </Typography>
              <form onSubmit={(e) => rsubmit(e)}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Rate"
                      id="rate"
                      variant="outlined"
                      placeholder="Enter the Rate per litre"
                      value={fuel.rate}
                      onChange={(e) => rhandle(e)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Quantity"
                      id="quantity"
                      variant="outlined"
                      placeholder="Quantity"
                      value={fuel.quantity}
                      onChange={(e) => rhandle(e)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Date"
                      id="date"
                      variant="outlined"
                      type="date"
                      placeholder="Date"
                      value={fuel.date}
                      onChange={(e) => rhandle(e)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      component="label"
                      fullWidth
                    >
                      Upload File
                      <input
                        type="file"
                        hidden
                        onChange={(e) => handlePdfFileChange(e)}
                      />
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                    >
                      SAVE
                    </Button>
                  </Grid>
                </Grid>
              </form>
              {loading && <CircularProgress style={{ marginTop: 20 }} />}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <ToastContainer />
    </div>
  );
}

export default withRouter(Fuelmanagment);
