import React, { useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import './Otp.css';

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let params = useParams();
    return <Component {...props} params={params} />;
  }
  return ComponentWithRouterProp;
}   
  
function Otpvalidation(props) {
  const { id } = useParams();
  console.log(id);
  const userId = localStorage.getItem("Registrationid");
  const navigate = useNavigate();
const [registerdata, setRegisterdata] = useState({
    token: "",
    userid:"",
  });
  const [subdetails, setSubdetails] = useState({
    username: JSON.parse(localStorage.getItem("RegistrationData")).username,
  });
  const [loading, setLoading] = useState(false);

  const Apiurl = process.env.REACT_APP_BASE_URL


  const rsubmit = (e) => {
    e.preventDefault();
    if (registerdata.token === "") {
      toast.error("OTP cannot be empty");
      return;
    }

    setLoading(true);
    axios
      .post(`${Apiurl}/api/OTPVerifications?token=${registerdata.token}`)
      .then(
        (res) => {
          setRegisterdata(res.data);
          setLoading(false);

          if (`${props.params.id}` == 0) {
            axios.post(`${Apiurl}/api/user/subscription/freetrial`, subdetails)
              .then(() => {
                navigate(`/Login`);
              });
          } else {
            navigate(`/totalamount/${id}`);
          }
          toast.success("OTP validated successfully and registered successfully");
        },
        () => {
          setLoading(false);
          toast.error("OTP validation failed");
        }
      );
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setRegisterdata({ ...registerdata, token: value });
  };

  return (
    <div className="col-6 mx-xl-auto">
      <div id="layoutAuthentication">
        <div id="layoutAuthentication_content">
          {loading && <div className="loading">Loading...</div>}
          <main>
            <div className="card">
              <div className="card-body">
                <div className="text-center">
                  <h1 className="display-5 mb-0">OTP Validation</h1>
                  <p>Enter the OTP sent to your email</p>
                </div>
                <form onSubmit={rsubmit}>
                  <div className="mb-6">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Enter OTP"
                      id="token"
                      value={registerdata.token}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="d-flex align-items-center justify-content-between mt-4 mb-0">
                    <button type="button" className="btn btn-secondary" onClick={() => navigate("/login")}>Cancel</button>
                    <input type="submit" className="btn btn-primary" value="Submit" />
                  </div>
                </form>
              </div>
            </div>
          </main>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}  

export default withRouter(Otpvalidation);
