import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { MdDeleteForever, MdArrowBack } from "react-icons/md";
import { Modal, Button, Table, TableRow, TableCell, TableHead, TableBody, Container, Typography, Grid, Card, CardContent, TextField, Select, MenuItem, Dialog, 
  DialogActions, 
  DialogContent, 
  DialogContentText,  DialogTitle, Box } from '@mui/material';
import axios from 'axios';

function VehicleDetails() {
  const [loading, setLoading] = useState(false);
  const { vehicleid } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [userdata, setUserData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState('');
  const [selectedVehicleId, setSelectedVehicleId] = useState('');

  const user = JSON.parse(localStorage.getItem('data'));
  const Apiurl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    getData();
    getUserData();
  }, [vehicleid, user.token]);

  const getData = async () => {
    setLoading(true);
    const token = user.token;

    try {
      const response = await axios.get(`${Apiurl}/api/uservehiclemapping/vehicleid=${vehicleid}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setData(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const toggleModal = () => {
    setShowModal(!showModal);
    if (!showModal) {
      setSelectedVehicleId(data[0]?.vehicleId || '');
    }
  };

  const getUserData = async () => {
    setLoading(true);
    const token = user.token;
    try {
      const { data } = await axios.get(`${Apiurl}/api/user/useraddress/`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUserData(data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  const onClicksubmit = async (e, id, pvehicleId, pusername) => {
    const token = JSON.parse(localStorage.getItem('data')).token;

    try {
      await axios({
        url: `${Apiurl}/api/uservehiclemapping`,
        method: 'delete',
        headers: { Authorization: `Bearer ${token}` },
        data: {
          id: id,
          vehicleId: pvehicleId,
          username: pusername,
        },
      });
      getData();
      toast('Deleted successfully');
    } catch (error) {
      console.error('Error deleting device user mapping:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token1 = user.token;

    try {
      await axios.post(`${Apiurl}/api/uservehiclemapping/create`, {
        vehicleid: selectedVehicleId,
        userid: selectedUserId
      }, {
        headers: { Authorization: `Bearer ${token1}` }
      });
      toast("Public user mapping added");
      getData();
      setShowModal(false);
      setSelectedUserId(null);

    } catch (error) {
      navigate("/users/PublicUser");
      toast.error("Failed to add public user mapping");
    }
  };

  const handleInputChange1 = (e) => {
    const { value } = e.target;
    setSelectedUserId(value);
  };

  return (
    <Container maxWidth="lg" className="my-4">
      {loading && <div className="loading"></div>}
      <Button onClick={() => navigate(-1)} startIcon={<MdArrowBack size={25} />} className="mb-3" >
   
      </Button>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={10} lg={20}>
          <Card className="mb-3">
            <CardContent>
              <Grid container justifyContent="space-between" alignItems="center" className="mb-2">
                <Grid item xs={12} md={3}>
                  <Typography variant="h5" component="div">Vehicles Details</Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Button onClick={toggleModal}  variant="contained" sx={{ float:"right", mb: 6 ,width: "150px"}}>Add Vehicle</Button>
                </Grid>
              </Grid>
              <Table>
                <TableHead>
                  <TableRow style={{ backgroundColor: 'grey', color: 'white' }}>
                    
                    <TableCell>Vehicle ID</TableCell>
                    <TableCell>Username</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((item) => (
                    <TableRow key={item.id}>
                    
                      <TableCell>{item.vehicleId}</TableCell>
                      <TableCell>{item.username}</TableCell>
                      <TableCell>
                        <Button onClick={(e) => onClicksubmit(e, item.id, item.vehicleId, item.username)} style={{ color: 'red',   fontSize: '30px'}}>
                          <MdDeleteForever />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Dialog open={showModal} onClose={toggleModal}>
     <DialogTitle>Add Vehicles</DialogTitle>
          <DialogContent>
            <DialogContentText>
           <Box component="form" >
                 <TextField
                label="Vehicle ID"
                variant="outlined"
                fullWidth
                value={selectedVehicleId}
                disabled
                className="mb-2"
              />
              <Select
                value={selectedUserId}
                onChange={handleInputChange1}
                
                variant="outlined"
                fullWidth
                label="Username"
              >
                <MenuItem value="">
                  <em>Select User ID</em>
                </MenuItem>
                {userdata.map((item) => (
                  <MenuItem key={item.id} value={item.id}>{item.username}</MenuItem>
                ))}
              </Select>
              </Box>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={toggleModal}>Close</Button>
                    <Button onClick={handleSubmit}>Submit</Button>
                </DialogActions>
            </Dialog>
              
            
    </Container>
  );
}

export default VehicleDetails;
