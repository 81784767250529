import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from 'axios';
import {
  Container,
  Card,
  CardContent,
  TextField,
  Button,
  CircularProgress,
  Typography,
  Box,
  IconButton,
} from '@mui/material';
import { IoMdArrowRoundBack } from "react-icons/io";

function AddDevice() {
  const Apiurl = process.env.REACT_APP_BASE_URL
  const token = JSON.parse(localStorage.getItem("data")).token;
  const [loading, setLoading] = useState(false);
  const [adevice, setAdevice] = useState({
    device: "",
    serialno: "",
    mobileno: "",
  });
  const navigate = useNavigate();

  const handleChange = (event, field) => {
    let actualValue = event.target.value;
    setAdevice({ ...adevice, [field]: actualValue });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    axios.post(`${Apiurl}/api/device/create`, adevice, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(response => response.data).then(
        (result) => {
          console.log(result);
          navigate("/users/Device");
          toast.success("Device added");
        },
        (error) => {
          console.error(error);
          setLoading(false);
        }
      )
  }

  return (
    <Container maxWidth="md">
      <Box my={4}>
        {loading && <CircularProgress />}
          
        <Card>
          <CardContent>
            <Box display="flex" alignItems="center" mb={2}>
              <IconButton onClick={() => navigate(-1)} color="primary" style={{width:"50px"}}>
                <IoMdArrowRoundBack />
              </IconButton>
            
              <Typography variant="h4" component="div" gutterBottom>
                Add Device
              </Typography>
            </Box>
            <form onSubmit={handleSubmit}>
              <Box mb={2}>
                <TextField
                  fullWidth
                  label="Device"
                  variant="outlined"
                  value={adevice.device}
                  onChange={(e) => handleChange(e, "device")}
                />
              </Box>
              <Box mb={2}>
                <TextField
                  fullWidth
                  label="Serial No"
                  variant="outlined"
                  value={adevice.serialno}
                  onChange={(e) => handleChange(e, "serialno")}
                />
              </Box>
              <Box mb={2}>
                <TextField
                  fullWidth
                  label="Mobile No"
                  variant="outlined"
                  value={adevice.mobileno}
                  onChange={(e) => handleChange(e, "mobileno")}
                />
              </Box>
              <Box textAlign="right">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={loading}
                >
                  Submit
                </Button>
              </Box>
            </form>
          </CardContent>
        </Card>
      </Box>
      <ToastContainer />
    </Container>
  );
}

export default AddDevice;
