import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from '../api/baseurl';
import CreatableSelect from 'react-select/creatable';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, Select, MenuItem } from "@mui/material";
import { css } from "@emotion/react";
import { Search as SearchIcon, Add as AddIcon } from '@mui/icons-material';

function Subscriptionmodule() {
  const Apiurl = process.env.REACT_APP_BASE_URL;
  const [loading, setLoading] = useState(false);
  const [subfeature, setSubfeature] = useState([]);
  const [subfeaturedropdown, setSubfeaturedropdown] = useState([]);
  const [module, setModule] = useState({
    submodel: "",
    region: "",
    cost: "",
    symbol: "",
    features: [],
  });
  const [value, setValue] = useState([]);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem("data")).token;

  const handleChange1 = (event, field) => {
    let actualValue = event.target.value;
    setModule({ ...module, [field]: actualValue });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const body = {
      ...module,
      features: value.map((v1) => ({
        features: v1.label
      }))
    };

    try {
      await axios.post(`${Apiurl}/api/submodelfeature`, body, {
        headers: { Authorization: `Bearer ${token}` },
      });
      toast("Subscription module added");
      setOpen(false); // Close the modal on success
    } catch (error) {
      toast("The Model name must be unique for the region");
    } finally {
      setLoading(false);
    }
  };

  const handleCreate = (inputValue) => {
    const newValue = [...subfeaturedropdown, { value: inputValue, label: inputValue }];
    setSubfeaturedropdown(newValue);
  };

  const getData = async () => {
    try {
      setLoading(true);
      const [regionResponse, featuresResponse, modelResponse] = await Promise.all([
        axios.get(`${Apiurl}/api/subscription-region`, { headers: { Authorization: `Bearer ${token}` } }),
        axios.get(`${Apiurl}/api/subscription-features`, { headers: { Authorization: `Bearer ${token}` } }),
        axios.get(`${Apiurl}/api/subscription-model`, { headers: { Authorization: `Bearer ${token}` } })
      ]);

      setSubfeature(featuresResponse.data);
      setSubfeaturedropdown(featuresResponse.data.map((sf) => ({ value: sf.features, label: sf.features })));
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div style={{textAlign:'right'}}>
      <Button variant="contained" color="primary" onClick={handleClickOpen}  startIcon={<AddIcon />}>
        Add Subscription 
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Subscription Module</DialogTitle>
        <DialogContent>
          {loading && (
            <div className="d-flex justify-content-center align-items-center vh-100">
              <div className="spinner-border text-primary" role="status" style={{ width: '3rem', height: '3rem' }}>
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
          {!loading && (
            <form onSubmit={handleSubmit}>
              <TextField
                margin="dense"
                label="Submodel"
                fullWidth
                value={module.submodel}
                onChange={(e) => handleChange1(e, "submodel")}
              />
              <Select
                fullWidth
                value={module.region}
                onChange={(e) => handleChange1(e, "region")}
                displayEmpty
              >
                <MenuItem value=""><em>Select region</em></MenuItem>
                {/* Map over regions if needed */}
              </Select>
              <TextField
                margin="dense"
                label="Cost"
                fullWidth
                value={module.cost}
                onChange={(e) => handleChange1(e, "cost")}
              />
              <Select
                fullWidth
                value={module.symbol}
                onChange={(e) => handleChange1(e, "symbol")}
                displayEmpty
              >
                <MenuItem value=""><em>Select symbol</em></MenuItem>
                {/* Map over symbols if needed */}
              </Select>
              <CreatableSelect
                onChange={(newValue) => setValue(newValue)}
                isClearable
                isMulti
                options={subfeaturedropdown}
              />
            </form>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary" disabled={loading}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Subscriptionmodule;
