import React, { useState } from 'react';
import "./Sidebar.css";
import { RiUserSettingsLine, RiDashboardFill } from "react-icons/ri";
import { MdReportGmailerrorred, MdOutlineOnDeviceTraining, MdSwitchAccount } from "react-icons/md";
import { FaUserGroup } from "react-icons/fa6";
import { IoMdSettings } from "react-icons/io";
import { ImTruck } from "react-icons/im";
import { FaChevronLeft, FaChevronRight, FaChevronDown } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Sidebar = ({ isOpen, toggleSidebar }) => {
  const [openDropdown, setOpenDropdown] = useState(null);

  const handleDropdownClick = (dropdownName) => {
    setOpenDropdown(openDropdown === dropdownName ? null : dropdownName);
  };

  const handleSidebarItemClick = () => {
    setOpenDropdown(null); // Close dropdown on sidebar item click
  };

  const data = localStorage.getItem("data");
  const role = data ? JSON.parse(data).role : null;

  return (
    <div className={`sidebar ${isOpen ? 'open' : 'closed'}`}>
      <div className="sidebar-header">
        <img src='\assets\track logo 1.png' alt='Logo' className='logo-img' />
        <button onClick={toggleSidebar} className="toggle-btn">
          {isOpen ? <FaChevronLeft /> : <FaChevronRight />}
        </button>
      </div>
      {role === "ROLE_ADMIN" &&
      <nav className="sidebar-nav">
        <ul className="nav-list">
          <li className="nav-item">
            <Link to="/users/admindashboard" onClick={handleSidebarItemClick}>
              <RiDashboardFill className="icon" />
              {isOpen && <span>Dashboard</span>}
            </Link>
          </li>

          <li className="nav-item">
            <Link to="/users/UserDevice" onClick={handleSidebarItemClick}>
              <MdOutlineOnDeviceTraining className="icon" />
              {isOpen && <span>User Device</span>}
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/users/VehicleList" onClick={handleSidebarItemClick}>
              <ImTruck className="icon" />
              {isOpen && <span>Vehicle Device</span>}
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/users/Publicuser" onClick={handleSidebarItemClick}>
              <FaUserGroup className="icon" />
              {isOpen && <span>Public User</span>}
            </Link>
          </li>
          <li className="nav-section">
            {isOpen && <span>OTHERS</span>}
          </li>
          <li className="nav-item">
            <Link to="#" onClick={() => handleDropdownClick('report')}>
              <MdReportGmailerrorred className="icon" />
              {isOpen && <span>Report</span>}
              {openDropdown === 'report' && isOpen ? <FaChevronDown className="dropdown-arrow" /> : null}
            </Link>
            {openDropdown === 'report' && isOpen && (
              <ul className="dropdown">
                <li><Link to="#">Fuel Report</Link></li>
                <li><Link to="#">Service Report</Link></li>
              </ul>
            )}
          </li>
          <li className="nav-item">
            <div className="account-settings-wrapper">
              <Link to="#" onClick={() => handleDropdownClick('account-settings')}>
                <RiUserSettingsLine className="icon" />
                {isOpen && <span className="account-settings-text">Account Settings</span>}
                <FaChevronDown className="dropdown-arrow" /> {/* Always visible arrow */}
              </Link>
              {openDropdown === 'account-settings' && isOpen && (
                <ul className="dropdown">
                  <li><Link to="/users/AccountSettings">Default Speed</Link></li>
                </ul>
              )}
            </div>
          </li>
        </ul>
      </nav>
}
{role === "ROLE_SUPER" &&
        <nav className="sidebar-nav">
          <ul className="nav-list">
            <li className="nav-item">
              <Link to ="/users/Dashboard" >
                <RiDashboardFill className="icon" />
                {isOpen && <span>Dashboard</span>}
              </Link>
            </li>

            <li className="nav-item">
              <Link to="/users/ExternalAccount">
                <MdSwitchAccount className="icon" />
                {isOpen && <span>External Account</span>}
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/users/Device">
                <MdOutlineOnDeviceTraining className="icon" />
                {isOpen && <span> Device</span>}
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/users/UserAcc">
                <FaUserGroup className="icon" />
                {isOpen && <span>User Account</span>}
              </Link>
            </li>
           
            <li className="nav-item">
              <Link to="#events" onClick={() => handleDropdownClick('report')}>
                <IoMdSettings  className="icon" />
                {isOpen && <span>Settings</span>}
                <FaChevronDown className="dropdown-arrow" />
              </Link>
              {openDropdown === 'report' && isOpen && (
                <ul className="dropdown">
                  <li><Link to="/users/UserDevice" >DeviceUserMapping</Link></li>
                  <li><Link to="/users/Subscriptionmodule">Subscription Module </Link></li>
                 
                  <li><Link to="/users/Subscriptionregion">Subscription Region </Link></li>
                  <li><Link to="/users/Tax">Tax </Link></li>
                  <li><Link to="/users/Features">Features </Link></li>
                  <li><Link to="/users/Permission">Permission </Link></li>
                </ul>
              )}
            </li>
           
          </ul>
        </nav>
      }
    </div>
  );
};

export default Sidebar;
