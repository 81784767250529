import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Card, CardContent, Typography, Button, CircularProgress, Box, Grid, Modal, TextField, FormControlLabel, Radio, RadioGroup, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { toast } from "react-toastify";
import Pagination from './Pagination';

function PublicUser() {

    const Apiurl = process.env.REACT_APP_BASE_URL;

    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const user = JSON.parse(localStorage.getItem('data'));
    const [showModal, setShowModal] = useState(false);
    const [UserMapping, setUserMapping] = useState(true);
    const [userData, setUserData] = useState([]);
  
    const [vehicleData, setVehicleData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 8;
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const paginatedCourses = Array.isArray(data)
        ? data.slice().reverse().slice(startIndex, endIndex)
        : [];

    const [newUser, setNewUser] = useState({
        username: '',
        addressName: '',
        address1: '',
        address2: '',
        address3: '',
        city: '',
        pincode: '',
        state: '',
        country: '',
    });

    const [existing, setExisting] = useState({
        userid: '',
        vehicleid: '',
    });

    useEffect(() => {
        getData();
        getUserData();
        getVehicleData();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewUser({ ...newUser, [name]: value });
    };

    const handleInputChange1 = (e) => {
        const { name, value } = e.target;
        setExisting({ ...existing, [name]: value });
    };

    const getData = async () => {
        setLoading(true);
        const token = user.token;
        try {
            const response = await axios.get(`${Apiurl}/api/uservehiclemapping`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            const uniqueData = response.data.filter((value, index, self) =>
                index === self.findIndex((t) => (
                    t.username === value.username
                ))
            );
            setData(uniqueData);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    const getUserData = async () => {
        const token = user.token;
        try {
            const { data } = await axios.get(`${Apiurl}/api/user/useraddress/`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setUserData(data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const getVehicleData = async () => {
        const token = user.token;
        const uid = user.userid;
        try {
            const { data } = await axios.get(`${Apiurl}/api/devicevehicle/userid=${uid}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setVehicleData(data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const toggleModal = () => {
        setShowModal(!showModal);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token1 = user.token;
    
        try {
            if (UserMapping) {
                // Find the user id based on username
                const selectedUser = userData.find(u => u.username === existing.userid);
                if (!selectedUser) {
                    throw new Error('User not found');
                }
                const userId = selectedUser.id;
    
                // Make sure all required fields are present in the request
                const requestData = {
                    userid: userId,
                    vehicleid: existing.vehicleid,
                };
    
                await axios.post(`${Apiurl}/api/uservehiclemapping/create`, requestData, { headers: { Authorization: `Bearer ${token1}` } });
                toast("Public user mapping added");
                setExisting({
                    userid: '',
                    vehicleid: '',
                });
            } else {
                await axios.post(`${Apiurl}/api/user/useraddress/create`, newUser, { headers: { Authorization: `Bearer ${token1}` } });
                toast("Public user added");
            }
    
            // Fetch the updated data
            await Promise.all([getData(), getUserData(), getVehicleData()]);
    
            // Close the modal after fetching data
            setShowModal(false);
        } catch (error) {
            console.error("Error submitting data:", error);
            toast.error("Failed to add public user/mapping");
            navigate("/users/Publicuser");
        }
    };

    if (loading) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}
            >
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box p={2}>
            <Button variant="contained" sx={{ justifyContent: "center", width: "100px" }} color="primary" onClick={toggleModal}>
                Add
            </Button>
            <Grid container spacing={2} mt={2}>
                {paginatedCourses.length > 0 ? paginatedCourses.map((item, index) => (
                    <Grid item xs={12} sm={6} md={3} key={`${item.userId}-${index}`}>
                        <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                            <CardContent sx={{ flexGrow: 1 }}>
                                <Typography variant="body1" color="text.secondary">
                                   
                                </Typography>   

                
                                <Typography onClick={() => navigate(`/users/PublicUserProfile/${item.userId}`)}>
                                    User Name: <Link>{item.username}</Link>
                                </Typography>
                                <Typography>
                                    Vehicle ID: <Link to={`/users/VehicleDetails/${item.vehicleId}`}>{item.vehicleId}</Link>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                )) : (
                    <Grid item xs={12}>
                        <Typography variant="body2" color="text.secondary">
                            No data available.
                        </Typography>
                    </Grid>
                )}
               
            </Grid>
            <div style={{ display: "flex",justifyContent:"space-around"}}>
            <Pagination
            
                    data={data.slice().reverse()}
                    currentPage={currentPage}
                    itemsPerPage={itemsPerPage}
                    onPageChange={setCurrentPage}
                   
                />
                </div>

            <Modal
                open={showModal}
                onClose={toggleModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '90%',
                    maxWidth: 800,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Add Account
                    </Typography>
                    <FormControl component="fieldset">
                        <RadioGroup row aria-label="userType" name="userType" value={UserMapping ? 'userMapping' : 'newUser'} onChange={(e) => setUserMapping(e.target.value === 'userMapping')}>
                            <FormControlLabel value="userMapping" control={<Radio />} label="User Mapping" />
                            <FormControlLabel value="newUser" control={<Radio />} label="New User" />
                        </RadioGroup>
                    </FormControl>
                    {UserMapping ? (
                        <Box>
                            <FormControl fullWidth margin="normal">
                                <InputLabel id="userid-label">User ID</InputLabel>
                                <Select
                                    labelId="userid-label"
                                    id="userid"
                                    name="userid"
                                    value={existing.userid}
                                    onChange={handleInputChange1}
                                >
                                    <MenuItem value="">
                                        <em>Select User ID</em>
                                    </MenuItem>
                                    {userData.map(item => (
                                        <MenuItem key={item.id} value={item.username}>{item.username}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl fullWidth margin="normal">
                                <InputLabel id="vehicleid-label">Vehicle ID</InputLabel>
                                <Select
                                    labelId="vehicleid-label"
                                    id="vehicleid"
                                    name="vehicleid"
                                    value={existing.vehicleid}
                                    onChange={handleInputChange1}
                                >
                                    <MenuItem value="">
                                        <em>Select Vehicle ID</em>
                                    </MenuItem>
                                    {vehicleData.map(item => (
                                        <MenuItem key={item.vehicleid} value={item.vehicleid}>{item.vehicleid}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    ) : (
                        <Box component="form" noValidate autoComplete="off">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        margin="normal"
                                        id="username"
                                        name="username"
                                        label="Username"
                                        value={newUser.username}
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        margin="normal"
                                        id="addressName"
                                        name="addressName"
                                        label="Address Name"
                                        value={newUser.addressName}
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        margin="normal"
                                        id="address1"
                                        name="address1"
                                        label="Address 1"
                                        value={newUser.address1}
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        margin="normal"
                                        id="address2"
                                        name="address2"
                                        label="Address 2"
                                        value={newUser.address2}
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        margin="normal"
                                        id="address3"
                                        name="address3"
                                        label="Address 3"
                                        value={newUser.address3}
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        margin="normal"
                                        id="city"
                                        name="city"
                                        label="City"
                                        value={newUser.city}
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        margin="normal"
                                        id="pincode"
                                        name="pincode"
                                        label="Pincode"
                                        value={newUser.pincode}
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        margin="normal"
                                        id="state"
                                        name="state"
                                        label="State"
                                        value={newUser.state}
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        margin="normal"
                                        id="country"
                                        name="country"
                                        label="Country"
                                        value={newUser.country}
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    )}
                    <Box mt={2} display="flex" justifyContent="flex-end">
                        <Button variant="contained" onClick={toggleModal} sx={{ mr: 1 }}>Close</Button>
                        <Button variant="contained" color="primary" onClick={handleSubmit}>Save changes</Button>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
}

export default PublicUser;
