import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { GoogleMap, Marker, InfoWindow, useJsApiLoader } from '@react-google-maps/api';
import { IoInformationCircle } from 'react-icons/io5';
import { Grid, Button, Switch, Typography, CircularProgress } from '@material-ui/core';
import Pagination from './Pagination';

const customizeMap = {
  width: '100%',
  height: '500px',
  position: 'relative',
};

const Apiurl = process.env.REACT_APP_BASE_URL;
const centerOfMap = { lat: 22.3511148, lng: 78.6677428 };
const libraries = ['places', 'drawing'];

const Public = () => {
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [agents, setAgents] = useState([]);
  const [vdevice, setVdevice] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [refreshTimer, setRefreshTimer] = useState(15);
  const [selectedVehicleInfo, setSelectedVehicleInfo] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedCourses = Array.isArray(vdevice)
    ? vdevice.slice().reverse().slice(startIndex, endIndex)
    : [];

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  useEffect(() => {
    if (isLoaded) {
      fetchData();
      startCountdownTimer();
    }
  }, [isLoaded]);

  const fetchData = async () => {
    setLoading(true);
    const { link } = params;
    if (link) {
      const locationApiUrl = `${Apiurl}/api/location/public/${link}`;
      const deviceApiUrl = `${Apiurl}/api/publicdevicevehicle/${link}`;

      try {
        const [locationResponse, deviceResponse] = await Promise.all([
          axios.get(locationApiUrl, { withCredentials: false }),
          axios.get(deviceApiUrl, { withCredentials: false }),
        ]);

        if (Array.isArray(locationResponse.data)) {
          setAgents(locationResponse.data);
        } else {
          console.error('Invalid data format received from the API:', locationResponse.data);
        }

        setVdevice(deviceResponse.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    }
  };

  const startCountdownTimer = () => {
    const countdownInterval = setInterval(() => {
      setRefreshTimer((prevTimer) => {
        if (prevTimer === 1) {
          submitFilter();
          return 15;
        }
        return prevTimer - 1;
      });
    }, 1000);

    return () => clearInterval(countdownInterval);
  };

  const submitFilter = async () => {
    try {
      const requestBody = generateVisibilityRequestBody();
      const response = await axios.post(
        `${Apiurl}/api/location/publicfilter/selected`,
        requestBody
      );
      setAgents(response.data);
    } catch (error) {
      console.error('Error filtering coordinates:', error);
    }
  };

  const generateVisibilityRequestBody = () => {
    if (vdevice.length === 0) {
      return [];
    }
    return vdevice.map((agent) => ({
      vehicleid: agent.vehicleid,
      visibility: !agent.disabled,
    }));
  };

  const toggleVehicleVisibility = (vehicleId) => {
    setVdevice((prevVdevice) =>
      prevVdevice.map((vehicle) => {
        if (vehicle.vehicleid === vehicleId) {
          return {
            ...vehicle,
            disabled: !vehicle.disabled,
          };
        }
        return vehicle;
      })
    );
  };

  const drawMarker = () => {
    // console.log("Agents data: ", agents); // Debug log
    return agents && agents.length > 0 ? agents.map((agent) => {
      if (agent && !agent.disabled) {
        return (
          <Marker
            key={agent.id}
            position={{
              lat: parseFloat(agent.latitude),
              lng: parseFloat(agent.longitude),
            }}
            onClick={() => handleMarkerClick(agent)}
          >
            {selectedMarker === agent && (
              <InfoWindow>
                <div>
                  <p>
                    <b>{agent.vehicleid}</b>
                  </p>
                  <p>Device Id: {agent.deviceid}</p>
                  <p>Latitude: {agent.latitude}</p>
                  <p>Longitude: {agent.longitude}</p>
                  <p>Latest Data: {agent.datetime}</p>
                  <p>Speed: {agent.speed}km/h</p>
                </div>
              </InfoWindow>
            )}
          </Marker>
        );
      }
      return null;
    }) : null;
  };

  const handleMarkerClick = (clickedMarker) => {
    setSelectedMarker(clickedMarker);
    setSelectedVehicleInfo(clickedMarker);
  };

  const handleInfoIconClick = (vehicle) => {
    const selectedMarker = agents.find((agent) => agent.vehicleid === vehicle.vehicleid);
    setSelectedMarker(selectedMarker);
    setSelectedVehicleInfo(selectedMarker);
  };

  const veiwAllvehicle = async () => {
    try {
      const requestBody = generateVisibilityRequestBody();
      const response = await axios.post(
        `${Apiurl}/api/location/publicfilter/viewall`,
        requestBody
      );
      const newVisibilityStatus = !vdevice.some((vehicle) => vehicle.disabled);

      setAgents(response.data);
      setVdevice((prevVdevice) =>
        prevVdevice.map((vehicle) => ({
          ...vehicle,
          disabled: newVisibilityStatus,
        }))
      );

      setSelectedVehicleInfo(null);
    } catch (error) {
      console.error('Error filtering coordinates:', error);
    }
  };

  const renderSelectedVehicleInfo = () => {
    if (!selectedMarker) {
      return null;
    }

    return (
      <InfoWindow
        position={{
          lat: parseFloat(selectedMarker.latitude),
          lng: parseFloat(selectedMarker.longitude),
        }}
        onCloseClick={() => setSelectedMarker(null)}
      >
        <div>
          <p>
            <b>{selectedMarker.vehicleid}</b>
          </p>
          <p>Device Id: {selectedMarker.deviceid}</p>
          <p>Latitude: {selectedMarker.latitude}</p>
          <p>Longitude: {selectedMarker.longitude}</p>
          <p>Latest Data: {selectedMarker.datetime}</p>
          <p>Speed: {selectedMarker.speed}km/h</p>
        </div>
      </InfoWindow>
    );
  };

  if (loadError) {
    return <div>Error loading Google Maps API</div>;
  }

  return (
    <div>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={3}>
              <div className="p-2 m-1">
                <Typography variant="h5">List of Vehicles</Typography>
              </div>
              <Button variant="outlined" onClick={veiwAllvehicle} style={{ width: '100px' }}>
                View All
              </Button>
              <div className="p--1">
                {paginatedCourses.map((agent) => (
                  <div key={agent.vehicleid} className="d-flex justify-content-between ">
                    <div>
                      <Switch
                        checked={!agent.disabled}
                        onChange={() => toggleVehicleVisibility(agent.vehicleid)}
                      />
                      {agent.vehicleid}
                    </div>
                    <IoInformationCircle
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleInfoIconClick(agent)}
                    />
                  </div>
                ))}
                <Button variant="outlined" onClick={submitFilter} style={{ width: '90px' }}>
                  Filter
                </Button>
              </div>
              <Pagination
                data={vdevice.slice().reverse()}
                currentPage={currentPage}
                itemsPerPage={itemsPerPage}
                onPageChange={setCurrentPage}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={9}
            >
              <div
                style={{
                  height: '600px',
                  width: '100%',
                  borderRadius: '10px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                  overflow: 'hidden',
                }}
              >
                {isLoaded ? (
                  <GoogleMap mapContainerStyle={customizeMap} center={centerOfMap} zoom={5}>
                    {drawMarker()}
                    {renderSelectedVehicleInfo()}
                  </GoogleMap>
                ) : (
                  <div>Loading...</div>
                )}
              </div>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
};

export default Public;
