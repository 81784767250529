import React, { useState } from 'react';
import { TextField, Box, Paper, Typography, Button } from '@mui/material';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const ResetPassword = () => {
  const [formData, setFormData] = useState({
    email: '',
    newPassword: '',
    confirmPassword: ''
  });
  const navigate = useNavigate();

  const Apiurl = process.env.REACT_APP_BASE_URL

  const handleChange = (event, field) => {
    setFormData({ ...formData, [field]: event.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${Apiurl}/api/resetPassword`, {
        username: formData.email,
        password: formData.newPassword,
        passwordConfirm: formData.confirmPassword
      });
      console.log(response.data);
      toast.success("Password reset successfully");
      navigate("/Login");
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong. Please try again.");
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="10vh"
      bgcolor="#"
    >
      <ToastContainer />
      <Paper elevation={3} sx={{ padding: 4, maxWidth: 400 }}>
        <Typography variant="h5" align="center" gutterBottom>
          Create New Password
        </Typography>
        <Typography variant="body2" align="center" gutterBottom style={{ marginBottom: '16px' }}>
          Enter your email and new password
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Email"
            type="email"
            margin="normal"
            variant="outlined"
            value={formData.email}
            onChange={(e) => handleChange(e, 'email')}
          />
          <TextField
            fullWidth
            label="New Password"
            type="password"
            margin="normal"
            variant="outlined"
            value={formData.newPassword}
            onChange={(e) => handleChange(e, 'newPassword')}
          />
          <TextField
            fullWidth
            label="Confirm Password"
            type="password"
            margin="normal"
            variant="outlined"
            value={formData.confirmPassword}
            onChange={(e) => handleChange(e, 'confirmPassword')}
          />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            type="submit"
            sx={{ mt: 2, mb: 2 }}
          >
            Reset Password
          </Button>
        </form>
      </Paper>
    </Box>
  );
};

export default ResetPassword;
