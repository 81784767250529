import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { GoogleMap, Marker } from '@react-google-maps/api';
import axios from '../api/baseurl';
import { IoSearchOutline } from 'react-icons/io5';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import {
  Container,
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
} from '@mui/material';

const Showtable = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const [error, setError] = useState(null);
  const [agents, setAgents] = useState([]);
  const [vehicle, setVehicle] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [startdate, setStartdate] = useState('');
  const [enddate, setEnddate] = useState('');
  const [response, setResponse] = useState({});
  const [vehi, setVehi] = useState([]);
  const [page, setPage] = useState(1);
  const [searchstate, setSearchstate] = useState(true);

  const token = JSON.parse(localStorage.getItem('data')).token;
  const userId = JSON.parse(localStorage.getItem('data')).userid;

  const pinSymbol = (color) => {
    return {
      path:
        'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z M -2,-30 a 2,2 0 1,1 4,0 2,2 0 1,1 -4,0',
      fillColor: `${color}`,
      fillOpacity: 1,
      strokeColor: '#000',
      strokeWeight: 2,
      scale: 1,
    };
  };

  const drawMarker = () => {
    return agents.map((agent) => (
      <Marker
        key={agent.id}
        position={{
          lat: agent.latitude,
          lng: agent.longitude,
        }}
        icon={pinSymbol(agent.colorCode)}
        onClick={() => console.log('Event Handler Called')}
      />
    ));
  }; 

  const onClickviewtable = () => {
    const { deviceid, vehicleid, colorCode } = params;
    navigate(`/users/Location/${deviceid}/${vehicleid}/${colorCode}`);
  };

  const handleGoBack = () => {
    navigate(-1); // Go back to the previous page
  };

  const handleChange = (e, field) => {
    const newdata = e.target.value;
    if (field === 'startdate') setStartdate(newdata);
    if (field === 'enddate') setEnddate(newdata);
  };

  useEffect(() => {
    axios
      .get(`/api/location/device=${params.deviceid}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setAgents(response.data);
      })
      .catch((error) => {
        setError(error);
      });
  }, [params.deviceid, token]);

  const selectPageHandle = (selectedPage) => {
    if (
      selectedPage >= 1 &&
      selectedPage <= Math.ceil(agents.length / 500) &&
      selectedPage !== page
    ) {
      setPage(selectedPage);
    }
  };

  const HandleStartandenddate = (e, sd, ed) => {
    setSearchstate(false);
    axios
      .get(
        `/api/LocationDo/deviceid=${params.deviceid}/startdate=${moment(sd, 'YYYY-MM-DD').format('DD-M-YYYY')}/enddate=${moment(
          ed,
          'YYYY-MM-DD'
        ).format('DD-M-YYYY')}`,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        setVehi(response.data);
      })
      .catch((error) => {
        setError(error);
      });
  };

  const onClickstatus = (e, vid) => {
    const checked = e.target.checked;
    axios
      .get(`/api/devicevehicle/devicevehicleUpdate/vehicleid=${vid}/value=${checked}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setVehicles(response.data);
      })
      .catch((error) => {
        setError(error);
      });
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  } else {
    return (
      <Container maxWidth="lg">
        <Card>
          <CardContent>
            <Typography variant="h5">Location Table</Typography>
            <Button onClick={handleGoBack} variant="contained" sx={{ mt: 2, mb: 2 }}>
              Back to location
            </Button>
            <Box sx={{ mb: 2 }}>
              <TextField
                label="Start Date"
                type="date"
                InputLabelProps={{ shrink: true }}
                value={startdate}
                onChange={(e) => handleChange(e, 'startdate')}
                sx={{ mr: 2 }}
              />
              <TextField
                label="End Date"
                type="date"
                InputLabelProps={{ shrink: true }}
                value={enddate}
                onChange={(e) => handleChange(e, 'enddate')}
                sx={{ mr: 2 }}
              />
              <Button
                variant="contained"
                onClick={(e) => HandleStartandenddate(e, startdate, enddate)}
                startIcon={<IoSearchOutline />}
              >
                Search
              </Button>
            </Box>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Id</TableCell>
                    <TableCell>DateTime</TableCell>
                    <TableCell>Latitude</TableCell>
                    <TableCell>Longitude</TableCell>
                    <TableCell>DeviceId</TableCell>
                    <TableCell>VehicleId</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {searchstate ? (
                    Array.isArray(agents) && agents.length > 0 ? (
                      agents
                        .slice(page * 500 - 500, page * 500)
                        .map((agent) => (
                          <TableRow key={agent.id}>
                            <TableCell>{agent.id}</TableCell>
                            <TableCell>{agent.datetime}</TableCell>
                            <TableCell>{agent.latitude}</TableCell>
                            <TableCell>{agent.longitude}</TableCell>
                            <TableCell>{agent.deviceid}</TableCell>
                            <TableCell>{`${params.vehicleid}`}</TableCell>
                          </TableRow>
                        ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan="6">No search results available.</TableCell>
                      </TableRow>
                    )
                  ) : Array.isArray(vehi) && vehi.length > 0 ? (
                    vehi
                      .slice(page * 500 - 500, page * 500)
                      .map((agent) => (
                        <TableRow key={agent.id}>
                          <TableCell>{agent.id}</TableCell>
                          <TableCell>{agent.datetime}</TableCell>
                          <TableCell>{agent.latitude}</TableCell>
                          <TableCell>{agent.longitude}</TableCell>
                          <TableCell>{agent.deviceid}</TableCell>
                          <TableCell>{`${params.vehicleid}`}</TableCell>
                        </TableRow>
                      ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan="6">No data available.</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {agents.length > 0 && (
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <Pagination
                  count={Math.ceil(agents.length / 500)}
                  page={page}
                  onChange={(e, value) => selectPageHandle(value)}
                  siblingCount={0}
                  boundaryCount={2}
                  color="primary"
                />
              </Box>
            )}
          </CardContent>
        </Card>
      </Container>
    );
  }
};

export default Showtable;
