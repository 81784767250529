import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { MdDeleteForever, MdArrowBack } from "react-icons/md";
import { 
    Box, 
    Button, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogContentText, 
    DialogTitle, 
    Grid, 
    IconButton, 
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow, 
    TextField, 
    Select, 
    MenuItem, 
    CircularProgress, 
    Typography 
} from '@mui/material';
import axios from "../api/baseurl";

function PublicUsername() {
    const [loading, setLoading] = useState(false);
    const { userid } = useParams();
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [vehicledata, setVehicleData] = useState([]);
    const [selectedVehicleId, setSelectedVehicleId] = useState('');
    const user = JSON.parse(localStorage.getItem('data'));
    const Apiurl = process.env.REACT_APP_BASE_URL;

    useEffect(() => {
        getData();
        getVehicleData();
    }, [userid, user.token]);

    const getData = async () => {
        setLoading(true);
        const token = user.token;

        try {
            const response = await axios.get(`${Apiurl}/api/uservehiclemapping/userId=${userid}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setData(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const getVehicleData = async () => {
        setLoading(true);
        const token = user.token;
        try {
            const response = await axios.get(`${Apiurl}/api/devicevehicle`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setVehicleData(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const toggleModal = () => {
        setShowModal(!showModal);
    };

    const onClickSubmit = async (e, id, pvehicleId) => {
        e.preventDefault();
        const token = user.token;

        try {
            await axios.delete(`${Apiurl}/api/uservehiclemapping`, {
                headers: { Authorization: `Bearer ${token}` },
                data: { id, vehicleid: pvehicleId, userid },
            });
            getData();
            toast.success('Deleted successfully');
        } catch (error) {
            console.error('Error deleting device user mapping:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token1 = user.token;

        if (!selectedVehicleId) {
            toast.error("Please select a vehicle ID");
            return;
        }

        try {
            await axios.post(`${Apiurl}/api/uservehiclemapping/create`, {
                vehicleid: selectedVehicleId,
                userid: userid
            }, {
                headers: { Authorization: `Bearer ${token1}` }
            });
            toast.success("Public user mapping added");
            getData();
            setShowModal(false);
            setSelectedVehicleId('');
        } catch (error) {
            toast.error("Failed to add public user mapping");
        }
    };

    return (
        <div>
      <div id="layoutDrawer_content">
        <div className="col-xl-12">
          {loading && <div className="loading"></div>}
          <main>
            <div className="col-12 mx-xl-auto profile-card">
            <Button onClick={() => navigate(-1)} startIcon={<MdArrowBack size={25} fontSize={90} />} className="mb-2" >
        
        </Button>
              <header className="bg-dark">
                <div className="container-xl px-5">
                  <h1 className="text-white py-1 mb-0 display-8">
                    Public Users 
                  </h1>
                </div>
              </header>
              <br />

              <div className="mwc-tab-bar">
                <div
                  className="mwc-tab "
                  label="Profile"
                  icon="person"
                  onClick={() => navigate(`/users/PublicUserProfile/${userid}`)}
                >
                  Profile
                </div>

                <div
                  className="mwc-tab active"
                  label="UserVehicleMapping"
                  icon="vehicles"
                  onClick={() => navigate(`/users/PublicUsername/${userid}`)}
                >
                  UserVehicleMapping
                </div>
              </div>
                <Grid item xs={6}>
                   
                    <Button variant="contained" onClick={toggleModal} sx={{ float:"right", mb: 6 ,width: "150px"}}>
                        Add Vehicle
                    </Button>
                    <card>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow style={{ backgroundColor: 'lightslategray' }}>
                                   
                                    <TableCell style={{ color: 'white' }}>Username</TableCell>
                                    <TableCell style={{ color: 'white' }}>Vehicle Id</TableCell>
                                    <TableCell style={{ color: 'white' }}>Delete</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.length > 0 ? data.map((item) => (
                                    <TableRow key={item.id}>
                                       
                                        <TableCell>{item.username}</TableCell>
                                        <TableCell>{item.vehicleId}</TableCell>
                                        <TableCell>
                                            <IconButton
                                                onClick={(e) => onClickSubmit(e, item.id, item.vehicleId)}
                                                style={{ color: 'red', fontSize: '50px' }}
                                            >
                                                <MdDeleteForever />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                )) : (
                                    <TableRow>
                                        <TableCell colSpan="4" align="center">No data available.</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    </card>
                </Grid>
          
            <Dialog open={showModal} onClose={toggleModal}>
                <DialogTitle>Add Vehicles</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Box component="form" noValidate autoComplete="off">
                            <TextField
                                margin="normal"
                                fullWidth
                                label="Username"
                                value={data.length > 0 ? data[0].username : ''}
                                InputProps={{ readOnly: true }}
                            />
                            <Select
                                fullWidth
                                value={selectedVehicleId}
                                onChange={(e) => setSelectedVehicleId(e.target.value)}
                                displayEmpty
                                margin="normal"
                            >
                                <MenuItem value="">
                                    <em>Select Vehicle ID</em>
                                </MenuItem>
                                {vehicledata.map(item => (
                                    <MenuItem key={item.vehicleid} value={item.vehicleid}>
                                        {item.vehicleid}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={toggleModal}>Close</Button>
                    <Button onClick={handleSubmit}>Submit</Button>
                </DialogActions>
            </Dialog>
       
        </div>
          </main>
        </div>
     </div>
    </div>
    );
}

export default PublicUsername;
