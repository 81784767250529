import React, { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { IoSearchOutline } from 'react-icons/io5';
import axios from '../api/baseurl';
import { GoogleMap, Marker, InfoWindow, Polygon, DrawingManager, Circle } from '@react-google-maps/api';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { FcHeatMap } from 'react-icons/fc';
import LocationMap from './LocationMap';
import HeatMap from './HeatMap';
import { Button, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { MdDeleteForever, MdArrowBack } from 'react-icons/md';

const centerOfIndia = { lat: 22.3511148, lng: 78.6677428 };

let date = new Date();
date.setDate(date.getDate());
let day = date.getDate().toString().padStart(2, '0');
let month = (date.getMonth() + 1).toString().padStart(2, '0');
let year = date.getFullYear();
let currentDate = `${year}-${month}-${day}`;

const styles = {
  button: {
    marginRight: 10,
  },
};

const Showtable = ({ classes }) => {
  const {deviceid,vehicleid}=useParams();
  console.log(vehicleid)
  const [showLocation, setShowLocation] = useState(true);
  const [showHeat, setShowHeat] = useState(false);
  const [id, setId] = useState(JSON.parse(localStorage.getItem('data')).userid);
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const showLocationHandler = () => {
    setShowLocation(true);
    setShowHeat(false);
  };

  const showHeatmapHandler = () => {
    setShowLocation(false);
    setShowHeat(true);
  };

  return (
    <div id="maincontainer">
      <button className="btn btn-link" onClick={() => navigate(-1)}>
        <MdArrowBack size={35} />
      </button>
      <div>
        <Grid container spacing={1} alignItems="center" justify="flex-end">
          <Grid item></Grid>
          <Grid item>
            <Button
              variant={showLocation ? 'contained' : 'outlined'}
              color="primary"
              onClick={showLocationHandler}
              className={classes.button}
              startIcon={<FaMapMarkerAlt />}
              style={{ width: '100%', fontSize: '12px' }}
            >
              Location Map
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant={showHeat ? 'contained' : 'outlined'}
              color="primary"
              onClick={showHeatmapHandler}
              className={classes.button}
              startIcon={<FcHeatMap />}
              style={{ width: '100%', fontSize: '12px' }}
            >
              Heat Map
            </Button>
          </Grid>
          <Grid item></Grid>
        </Grid>
      </div>
      <div>{showLocation ? <LocationMap /> : <HeatMap />}</div>
    </div>
  );
};

export default withStyles(styles)(Showtable);
