import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { token } from "../auth/service";
import moment from "moment";
import { IoSearchOutline } from "react-icons/io5";
import axios from '../api/baseurl';
import { GoogleMap, HeatmapLayer, LoadScript } from '@react-google-maps/api'; 
import { Box, Typography, Card, CardContent, Grid, TextField, Button, CircularProgress } from '@mui/material';

const centerOfIndia = { lat: 22.3511148, lng: 78.6677428 };
const googleMapsApiKey = "AIzaSyCI0IsM1EWVG5PKM4mCHNdV6NK8Lja4-lM";
const libraries = ['visualization'];

const HeatMap = () => {
  const { deviceid } = useParams();
  const navigate = useNavigate();
  
  const [monthYear, setMonthYear] = useState(moment().format('YYYY-MM'));
  const [heatmapData, setHeatmapData] = useState([]);
  const [mapCenter, setMapCenter] = useState(centerOfIndia);
  const [isLoading, setIsLoading] = useState(true);
  const Apiurl = process.env.REACT_APP_BASE_URL
  
  
  useEffect(() => {
    fetchHeatmapData();
  }, [monthYear]);
  
  const fetchHeatmapData = () => {
    setIsLoading(true);
    const [year, month] = monthYear.split("-");
    axios.get(`${Apiurl}/api/heatmap/deviceid=${deviceid}/month=${month}/year=${year}`, {
      headers: { Authorization: `Bearer ${token()}` }
    })
    .then(response => {
      if (response.data.length > 0) {
        const latestLocation = response.data[response.data.length - 1];
        setHeatmapData(response.data);
        setMapCenter({
          lat: parseFloat(latestLocation.latitude),
          lng: parseFloat(latestLocation.longitude),
        });
      }
      setIsLoading(false);
    })
    .catch(error => {
      console.error("Error fetching heatmap data:", error);
      setIsLoading(false);
    });
  };

  const getHeatmapGradient = () => [
    'rgba(0, 255, 0, 0)', 
    'rgba(0, 255, 0, 1)', 
    'rgba(255, 255, 0, 1)', 
    'rgba(255, 165, 0, 1)', 
    'rgba(255, 0, 0, 1)'
  ];

  const handleMonthYearChange = (e) => {
    setMonthYear(e.target.value);
  };

  const handleSubmit = () => {
    fetchHeatmapData();
  };

  const heatmapOptions = {
    gradient: getHeatmapGradient(),
    radius: 20,
    opacity: 0.6
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h5" gutterBottom>
        Device ID: {deviceid}
      </Typography>
      <Card>
        <CardContent>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={5}>
              <TextField
                label="Select Month and Year"
                type="month"
                fullWidth
                value={monthYear}
                onChange={handleMonthYearChange}  
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={2} textAlign="center">
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                startIcon={<IoSearchOutline />}
                style={{width: '100%'}}
              >
                Search
              </Button>
            </Grid>
          </Grid>
          <Box sx={{ mt: 2, height: 500, width: '100%' }}>
            <LoadScript googleMapsApiKey={googleMapsApiKey} libraries={libraries}>
              <GoogleMap
                mapContainerStyle={{ height: '100%', width: '100%' }}
                center={mapCenter}
                zoom={11}
              >
                {isLoading ? <CircularProgress /> : (
                  <HeatmapLayer
                    data={heatmapData.map(pos => new window.google.maps.LatLng(pos.lat, pos.lng))}
                    options={heatmapOptions}
                  />
                )}
              </GoogleMap>
            </LoadScript>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default HeatMap;
