import React, { useState, useEffect } from 'react';
import axios from '../api/baseurl';
 
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
 
function AddVehicle() {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({ maxspeed: '', vehiclemileage: '' });
 
  const [vehicle, setvehicle] = useState({
    userid: JSON.parse(localStorage.getItem("data")).userid,
    deviceid: "",
    vehicleid: "",
    startdate: "",
    enddate: "",
    image: null,
    imageMeta: "",
    vehiclemileage:"",
    disabled:"True",
    maxspeed:""
  });
  const Apiurl = process.env.REACT_APP_BASE_URL
 
  const [device, setDevice] = useState([]);

  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    const uid = JSON.parse(localStorage.getItem("data")).userid;
    const { data } = await axios.get(`${Apiurl}/api/deviceusermapping/userid=${uid}/disabled=${false}`, {
      headers: { Authorization: `Bearer ${token}` }
    });
    setDevice(data);
    console.log(data);
  };
 
  useEffect(() => {
    getData();
  }, []);
 
  function getBase64FromFile(file) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        const [metadata, content] = reader.result.split(",");
        resolve({ metadata, content });
      };
      reader.onerror = function (error) {
        reject(error);
      };
    });
  }
 
  const handleImageChange = async (event) => {
    const convertedImage = await getBase64FromFile(event.target.files[0]);
    const imageObject = {
      ...vehicle,
      imageMeta: convertedImage.metadata,
      image: convertedImage.content,
    };
    setvehicle(imageObject);
  }
 
  const navigate = useNavigate();
 
  const handleChange = (event, field) => {
    let actualValue = event.target.value;
 
    if (field === 'maxspeed' || field === 'vehiclemileage') {
      if (!/^\d*$/.test(actualValue)) {
        setErrors({ ...errors, [field]: 'Please enter a valid number' });
      } else if (actualValue === '0') {
        setErrors({ ...errors, [field]: 'Value must be greater than 0' });
      } else {
        setErrors({ ...errors, [field]: '' });
      }
    }
    setvehicle({ ...vehicle, [field]: actualValue });
  };
 
  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
 
    if (vehicle.vehiclemileage === '0' || vehicle.vehiclemileage === '') {
      setErrors({ ...errors, vehiclemileage: 'Mileage must be greater than zero' });
      setLoading(false);
      return;
    }
 
    if (vehicle.maxspeed === '0' || vehicle.maxspeed === '') {
      setErrors({ ...errors, maxspeed: 'Max speed must be greater than zero' });
      setLoading(false);
      return;
    }
    const token = JSON.parse(localStorage.getItem("data")).token;
 
    await axios.post(`${Apiurl}/api/devicevehicle/create`, vehicle, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        navigate("/users/VehicleList");
        toast("Device added to Vehicle");
      })
      .catch((error) => {
        navigate("/users/VehicleList");
        toast.error("The Deviceid must be unique");
        setLoading(false);
      });
 
    const id = vehicle.deviceid;
    axios.get(`${Apiurl}/api/deviceusermapping/DeviceUserMappingUpdate/deviceid=${id}/value=false`, {
      headers: { Authorization: `Bearer ${token}` }
    })
    .then((res) => {
      setLoading(false);
    });
  }
 
  return (
    <Box sx={{ p: 3 }}>
      <ToastContainer />
      <Box sx={{ maxWidth: 800, mx: 'auto' }}>
        {loading && <div className="loading"></div>}
        <Card>
          <CardContent>
           
          <Typography variant="h5" component="div" gutterBottom>
              Add Device to Vehicle
            </Typography>
            <hr className="mt-0 mb-5" />
 
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box sx={{ flex: 1, mr: 2 }}>
                <Card>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      Profile Image
                    </Typography>
                    <Box sx={{ textAlign: 'center' }}>
                      {vehicle.image && (
                        <CardMedia
                          component="img"
                          image={`${vehicle?.imageMeta},${vehicle?.image}`}
                          alt="Vehicle"
                          sx={{ maxWidth: 150, maxHeight: 150, borderRadius: '50%', mx: 'auto' }}
                        />
                      )}
                      <Typography variant="body2" color="text.secondary">
                        JPG or PNG no larger than 5 MB
                      </Typography>
                      <Button variant="contained" component="label" sx={{ mt: 2 }}>
                        Upload File
                        <input
                          type="file"
                          hidden
                          onChange={handleImageChange}
                          accept="image/*"
                        />
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </Box>
 
              <Box component="form" onSubmit={handleSubmit} sx={{ flex: 2, ml: 2 }}>
                <FormControl fullWidth margin="normal">
                  <InputLabel id="deviceid-label">Device ID</InputLabel>
                  <Select
                    labelId="deviceid-label"
                    id="deviceid"
                    value={vehicle.deviceid}
                    label="Device ID"
                    onChange={(e) => handleChange(e, "deviceid")}
                  >
                    <MenuItem value=""><em>Select devices</em></MenuItem>
                    {device.map((device) => (
                      <MenuItem key={device.deviceid} value={device.deviceid}>
                        {device.deviceid}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
 
                <TextField
                  fullWidth
                  margin="normal"
                  id="vehicleid"
                  label="Vehicle ID"
                  value={vehicle.vehicleid}
                  onChange={(e) => handleChange(e, "vehicleid")}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  id="startdate"
                  label="Start Date"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  value={vehicle.startdate}
                  onChange={(e) => handleChange(e, "startdate")}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  id="vehiclemileage"
                  label="Vehicle Mileage"
                  value={vehicle.vehiclemileage}
                  onChange={(e) => handleChange(e, "vehiclemileage")}
                  error={!!errors.vehiclemileage}
                  helperText={errors.vehiclemileage}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  id="maxspeed"
                  label="Max Speed"
                  value={vehicle.maxspeed}
                  onChange={(e) => handleChange(e, "maxspeed")}
                  error={!!errors.maxspeed}
                  helperText={errors.maxspeed}
                />
 
                <Box sx={{ textAlign: 'right' }}>
                  <Button type="submit" variant="contained" color="primary" disabled={loading}>
                    Submit
                  </Button>
                </Box>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};
 
export default AddVehicle;