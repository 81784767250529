import React, { useState, useEffect } from "react";
import { loginUser } from "../auth/service";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { doLogin, isLoggedIn } from "../auth";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Box, Button, TextField, Typography, Paper, CircularProgress, Backdrop } from '@mui/material';
import { useAuth } from '../auth/AuthContext';


function Login() {
    const { login } = useAuth();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [sessionTimeoutTriggered, setSessionTimeoutTriggered] = useState(false);
    const [logindata, setLogindata] = useState({ username: "", password: "" });

    let sessionCheckInterval;

    useEffect(() => {
        if (isLoggedIn()) {
            window.history.replaceState(null, "", window.location.href);
        } else {
            sessionCheckInterval = setInterval(() => {
                const lastActivity = localStorage.getItem("lastActivity");
                const currentTime = new Date().getTime();
                const sessionTimeout = 5 * 60 * 60 * 1000; 

                if (lastActivity && currentTime - lastActivity > sessionTimeout) {
                    clearInterval(sessionCheckInterval);
                    handleSessionTimeout();
                }
            }, 5 * 60 * 60 * 1000);
        }

        return () => {
            clearInterval(sessionCheckInterval);
        };
    }, []);

    const handleChange = (event, field) => {
        let actualValue = event.target.value;
        setLogindata({ ...logindata, [field]: actualValue });
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        if (logindata.username.trim() === "" || logindata.password.trim() === "") {
            toast.warn("Username or password is required!!");
            setLoading(false);
            return;
        }
        loginUser(logindata)
            .then((data) => {
                toast.success("Login success");
                login();
                doLogin(data, () => {
                    if (JSON.parse(localStorage.getItem("data")).role === "ROLE_SUPER") {
                        navigate("/users/dashboard");
                    } else {
                        navigate("/users/admindashboard");
                    }
                });
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                if (error.response && error.response.status === 401) {
                    toast.error(error.response.data.message);
                } else if (error.response && error.response.status === 423) {
                    toast.error("Your account is locked. Please contact the administrator.");
                } else if (error.response && error.response.status === 404) {
                    const logindetails = { username: logindata.username };
                    localStorage.setItem("Logindetails", JSON.stringify(logindetails));
                    toast.error(error.response.data.message);
                    navigate("/Subscription");
                } else {
                    toast("Something went wrong!!!");
                }
            });
    };

    const clearSession = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("data");
    };

    const handleSessionTimeout = () => {
        if (!sessionTimeoutTriggered) {
            clearSession();
            toast.error("Your session has expired. Please log in again.");
            navigate("/Signin");
            setSessionTimeoutTriggered(true);
        }
    };

    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="60vh"
                sx={{ 
                    bgcolor: loading ? "none" : "transparent",
                    filter: loading ? "blur(4px)" : "none",
                    transition: "filter 0.3s ease"
                }}
            >
                <ToastContainer />
                <Paper elevation={3} sx={{ padding: 4, maxWidth: 400 }}>
                    <Typography variant="h5" align="center" gutterBottom>
                        Sign In
                    </Typography>
                    <form onSubmit={handleFormSubmit}>
                        <TextField
                            fullWidth
                            label="Email"
                            type="email"
                            margin="normal"
                            variant="outlined"
                            value={logindata.username}
                            onChange={(e) => handleChange(e, "username")}
                        />
                        <TextField
                            fullWidth
                            label="Password"
                            type="password"
                            margin="normal"
                            variant="outlined"
                            value={logindata.password}
                            onChange={(e) => handleChange(e, "password")}
                        />
                        <Box display="flex" justifyContent="flex-end">
                            <Link to="/ForgotPassword" variant="body2" style={{ marginTop: "8px", cursor: "pointer" }}>
                                Forgot Password?
                            </Link>
                        </Box>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={loading}
                            sx={{ mt: 2, mb: 2 }}
                        >
                            Continue
                        </Button>
                    </form>
                    <Typography variant="body2" align="center">
                        Not a Member yet?{" "}
                        <Link to="/subscription" variant="body2" style={{ cursor: "pointer" }}>
                            Sign up
                        </Link>
                    </Typography>
                </Paper>
            </Box>
        </>
    );
}

export default Login;
