import React from 'react';
import { useLocation,useNavigate } from 'react-router-dom';
import { Card, CardContent, Typography, Grid,Link } from '@mui/material';
 
function Search() {
  const navigate = useNavigate();
  const location = useLocation();
  const { vehicles } = location.state || { vehicles: [] };
 console.log(vehicles);

  return (
    <div>
      <h1>Search Results</h1>
      <Grid container spacing={3}>
        {vehicles.length > 0 ? (
          vehicles.map((vehicle, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
              <Card>
                <CardContent>
                  <Typography variant="h5" component="div">
                    {vehicle.objectname}
                  </Typography>
                  <Typography variant="subtitle1" component="div" color="textSecondary">
                    Device ID: {vehicle.objectid}
                  </Typography>
                  <Typography variant="subtitle1" component="div" color="textSecondary">
                    Object Type: {vehicle.objecttype}
                  </Typography>
                 
                  <Typography onClick={() => navigate(`/users/VehicleList/${vehicle.objectid}`)}>
                                   
                                   <Link> View</Link>
                                </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <Typography variant="h5" sx={{ padding: '30px' }}>
            No results found
          </Typography>
        )}
      </Grid>
    </div>
  );
}
 
export default Search;