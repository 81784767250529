import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from '../api/baseurl';
import { FaEdit } from 'react-icons/fa';
import { MdDeleteForever } from 'react-icons/md';
import { toast } from 'react-toastify';
import {
  Box, Container, Grid, Card, CardContent, Typography, Button, CircularProgress, Switch,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Dialog, DialogActions,
  DialogContent, DialogContentText, DialogTitle, TextField
} from '@mui/material';
import Pagination from '../Component/Pagination';
import { ImSearch } from "react-icons/im";
import { Search as SearchIcon, Add as AddIcon } from '@mui/icons-material';

function Device() {
  const Apiurl = process.env.REACT_APP_BASE_URL;
  const [device, setDevice] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem("data")).token;
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const [searchInput, setSearchInput] = useState('');

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);

    try {
      const { data } = await axios.get(`${Apiurl}/api/device`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setDevice(data);
      setFilteredData(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchInputChange = (event) => {
    const value = event.target.value;
    setSearchInput(value);

    const filtered = device.filter(item => {
      const lowerValue = value.toLowerCase();
      return (
        (item.id && item.id.toString().toLowerCase().includes(lowerValue)) ||
        (item.device && item.device.toLowerCase().includes(lowerValue)) ||
        (item.serialno && item.serialno.toLowerCase().includes(lowerValue)) ||
        (item.mobileno && item.mobileno.toLowerCase().includes(lowerValue))
      );
    });

    setFilteredData(filtered);
  };

  const handleOpen = (device) => {
    setSelectedDevice(device);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedDevice(null);
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`${Apiurl}/api/device`, {
        headers: { Authorization: `Bearer ${token}` },
        data: selectedDevice,
      });
      setDevice(device.filter((item) => item.id !== selectedDevice.id));
      toast.success('Deleted successfully');
      handleClose();
    } catch (error) {
      console.error('Error deleting device:', error);
      toast.error('Failed to delete');
    }
  };

  const onClickhandler = async (e, id) => {
    const checked = e.target.checked;
    setLoading(true);
    try {
      await axios.get(`${Apiurl}/api/device/deviceUpdate/id=${id}/value=${checked}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      getData();
    } catch (error) {
      console.error('Error updating device status:', error);
    } finally {
      setLoading(false);
    }
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedCourses = filteredData.slice(startIndex, endIndex);

  return (
    <Box p={3}>
      {loading && (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
          <CircularProgress />
        </Box>
      )}

      {!loading && (
        <Container>
          <Box mb={3} display="flex" alignItems="center" sx={{ gap: 1 }}>
            <ImSearch style={{ marginRight: '8px', fontSize: "25px" }} />
            <TextField
              label="Search by Device ID, Serial No, or Mobile No"
              variant="outlined"
              value={searchInput}
              onChange={handleSearchInputChange}
              sx={{ width: "300px" }}
            />
            {`${JSON.parse(localStorage.getItem("data")).role}` === "ROLE_SUPER" && (
              <Box display="flex" justifyContent="flex-end" flexGrow={1}>
                <Link to="/users/AddDevice" style={{ textDecoration: 'none' }}>
                  <Button variant="contained" color="primary" sx={{ width: "140px",height:"90%" }} startIcon={<AddIcon/>}>
                    Add Device
                  </Button>
                </Link>
              </Box>
            )}
          </Box>

          {`${JSON.parse(localStorage.getItem("data")).role}` === "ROLE_SUPER" && (
            <Card>
              <CardContent>
                <Typography variant="h4" component="div">Device Details</Typography>
                <TableContainer component={Paper} sx={{ mt: 3 }}>
                  <Table>
                    <TableHead>
                      <TableRow sx={{ backgroundColor: "grey" }}>
                        <TableCell sx={{ color: "white" }}>Id</TableCell>
                        <TableCell sx={{ color: "white" }}>Device Id</TableCell>
                        <TableCell sx={{ color: "white" }}>Serial No</TableCell>
                        <TableCell sx={{ color: "white" }}>Mobile No</TableCell>
                        <TableCell sx={{ color: "white" }}>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {paginatedCourses.map(agent => (
                        <TableRow key={agent.id}>
                          <TableCell>{agent.id}</TableCell>
                          <TableCell>{agent.device}</TableCell>
                          <TableCell>{agent.serialno}</TableCell>
                          <TableCell>{agent.mobileno}</TableCell>
                          <TableCell>
                            <Switch checked={!agent.disabled} onChange={(e) => onClickhandler(e, agent.id)} />
                            <Link to={`/users/Editdevice/${agent.id}`} style={{ marginLeft: "10px", textDecoration: "none", fontSize: "17px" }}>
                              <FaEdit />
                            </Link>
                            &nbsp; &nbsp; &nbsp;
                            <MdDeleteForever
                              style={{ color: 'red', fontSize: '1.5em', cursor: 'pointer' }}
                              onClick={() => handleOpen(agent)}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          )}

          {`${JSON.parse(localStorage.getItem("data")).role}` === "ROLE_ADMIN" && (
            <Card>
              <CardContent>
                <Typography variant="h5" component="div">Device User Details</Typography>
                <TableContainer component={Paper} sx={{ mt: 3 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell>Device Id</TableCell>
                        <TableCell>Serial No</TableCell>
                        <TableCell>Mobile No</TableCell>
                        <TableCell>Status</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {paginatedCourses.map(agent => (
                        <TableRow key={agent.id}>
                          <TableCell>{agent.id}</TableCell>
                          <TableCell>{agent.device}</TableCell>
                          <TableCell>{agent.serialno}</TableCell>
                          <TableCell>{agent.mobileno}</TableCell>
                          <TableCell>
                            <Switch checked={!agent.disabled} onChange={(e) => onClickhandler(e, agent.id)} />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          )}
          <Pagination
            data={filteredData.slice().reverse()}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            onPageChange={setCurrentPage}
            style={{ justifyContent: "flex-end" }}
          />
        </Container>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            If you delete this device, you will lose the data completely. Are you sure?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            No
          </Button>
          <Button onClick={handleDelete} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default Device;
