
import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';

function Supersubscription() {
  const Apiurl = process.env.REACT_APP_BASE_URL
  const [checkedmap, setCheckedmap] = useState(false);
  const [sub, setSub] = useState([]);
  const [subfeatur, setSubfeatur] = useState([]);
  const [subfeaturmodel, setSubfeaturmodel] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const url = `${Apiurl}/api/subscription-model`;
  const navigate = useNavigate();

  
  const getData = async () => {
    setIsLoading(true);

    const sf = await axios.get(`${Apiurl}/api/subscription-features`);
    setSubfeatur(sf.data);

    const sfmm = await axios.get(`${Apiurl}/api/SubmodelandfeatureDo`);
    setSubfeaturmodel(sfmm.data);

    const prof1 = await axios.get(url);
    setSub(prof1.data);

    setIsLoading(false);
  }

  useEffect(() => {
    getData();
  }, []);


  return (
    <div id="layoutDrawer_content">
      <main>
        <div className="col-8 mx-xl-auto ps-xl-6">
          <div className="card card-raised">
            <div className="card-body">
           
              <div className="container-xl p-6">
                <div className="text-center mb-5">
                  <h1>Subscribe for the Required Plans</h1>
                  <p className="mb-0">No contracts, no surprise fees</p>
                </div>
                <div className="row">
                  {subfeaturmodel.map((subscription) => {
                    return (
                      <div className="col-sm-3">
                        <div className="card card-raised h-100" >
                          {/* style={{ backgroundColor:"#808080"}} */}
                          <div className="card-body">
                            <h2 className="overline">{subscription.submodel}</h2>
                            <div className="d-flex align-items-center mb-3">
                              <div className="display-5">{subscription.cost}</div>
                              <div className="text-muted ms-2">{subscription.region}</div>
                            </div>
                            <p>For most businesses that want to optimize web queries</p>
                            {subscription.features.map((sbf) => {
                              return (
                                <ul className="list-group list-group-flush">
                                  <li className="list-group-item px-4">
                                    <div className="d-flex align-items-center">
                                      <i className="material-icons icon-xs me-4">check</i>
                                      {sbf.features}
                                    </div>
                                  </li>
                                </ul>
                              );
                            })}
                          </div>
                          <div className="card-footer border-0 bg-transparent pt-0">
                           
                           
                          </div>
                        </div>
                        <br />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Supersubscription;
